import useAxios from "../utilities/useAxios";

const BACKEND_DEV_URL = process.env.REACT_APP_BACKEND_URL;

export const deleteUpload = async (fileName) => {
    return useAxios
      .delete(`${BACKEND_DEV_URL}/files/delete-file/${fileName.fileName}`)
      .catch((error) => console.error(error));
  };

export const uploadFile = async (sectionOwnerId, data) => {
  return useAxios
      .post(`${BACKEND_DEV_URL}/files/upload/file-section/${sectionOwnerId}`, data)
      .catch((error) => console.error(error));
}
  