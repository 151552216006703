import {
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Tooltip,
} from "@mui/material";
import { UserMaintenanceColumnSchema } from "../../constants";
import EditIcon from "@mui/icons-material/Edit";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { CustomPagination } from "../custom-pagination/CustomPagination";
import moment from "moment";
import { Typography } from "antd";

const TableCellStyled = styled(TableCell)(() => ({
  textAlign: "left",
  fontSize: "0.90rem",
  padding: "20px 20px",
  width: "auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  borderBottom: "1px solid #e0e0e0",
  "&:first-child": {
    padding: "0px 30px",
  },
  "&:last-child": {
    width: "100px",
  },
}));

const TableColumnCellStyled = styled(TableCell)(() => ({
  "&:first-child": {
    padding: "0px 30px",
  },
  textAlign: "left",
  fontSize: ".95rem",
  fontWeight: "bold",
  textTransform: "capitalize",
  padding: "20px 20px",
  width: "auto",
  borderBottom: "1px solid #e0e0e0",
  backgroundColor: "#f7f7f7",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "&:last-child": {
    width: "150px",
  },
}));

export const UserTable = ({
  data,
  handleDisplayData,
  handleSort,
  handleStatus,
  pagination,
  handleChangePage,
  loading,
  usersPageNumber,
  sorting,
}) => {
  const handleNextPage = () => {
    handleChangePage(usersPageNumber + 1);
  };

  const handlePreviousPage = () => {
    handleChangePage(usersPageNumber - 1);
  };

  const totalRange = () => {
    const start = data?.length > 0 ?  pagination?.pageable?.pageNumber * pagination?.size + 1 : 0;
    const end =data?.length > 0 ?  Math.min(
      pagination?.pageable?.pageNumber * pagination?.size +
        pagination?.numberOfElements,
      pagination?.totalElements
    ) : 0;
    const total = data?.length > 0 ?  pagination?.totalElements : 0;
    return { start, end, total };
  };

  const { start, end, total } = totalRange();

  return (
    <>
      <TableContainer
        sx={{
          height: "100%",
          margin: "0% 3%",
          width: "100%",
          marginTop: "2%",
        }}
        component={Paper}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {UserMaintenanceColumnSchema.map((column) => (
                <TableColumnCellStyled key={column.id}>
                  {column.label}
                  {column.hasSorting && (
                    <IconButton
                      onClick={() => {
                        handleSort(column.id);
                      }}
                      sx={{ width: 20, height: 20 }}
                    >
                      {sorting.column === column.id &&
                      sorting.order === "ASC" ? (
                        <ArrowUpwardIcon
                          sx={{ color: "#000", fontSize: "18px" }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{ color: "#000", fontSize: "18px" }}
                        />
                      )}
                    </IconButton>
                  )}
                </TableColumnCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <TableRow key={index}>
                <TableCellStyled>{row.employeeId}</TableCellStyled>
                <TableCellStyled>{row.fullname}</TableCellStyled>
                <TableCellStyled>
                  {row?.sections?.map((item) => item.name).join(", ")}
                </TableCellStyled>
                <TableCellStyled>
                  {moment(row.dateCreated).format("YYYY-MM-DD")}
                </TableCellStyled>
                <TableCellStyled>
                  <Chip
                    style={{
                      backgroundColor: row.status === 1 ? "#379f86" : "#FAAA4C",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                    label={row.status === 1 ? "ACTIVE" : "INACTIVE"}
                  />
                </TableCellStyled>
                <TableCellStyled>
                  <Grid container gap={0.5} justifyContent="left">
                    <Tooltip title="Update User" placement="top">
                      <IconButton onClick={() => handleDisplayData(row)}>
                        <EditIcon sx={{ color: "#444444" }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </TableCellStyled>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {!loading && data.length === 0 && (
          <Box marginTop={2}>
            <Typography textAlign={"center"}>No Data Available</Typography>
          </Box>
        )}
      </TableContainer>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="0px 20px"
        backgroundColor="#ffffff"
        width="100%"
        borderRadius="2px"
        margin="0% 3%"
      >
        <Grid container alignItems="center">
          <Grid item xs={4} />
          <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
            <CustomPagination
              pageNumber={usersPageNumber ?? 1}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              disableFirst={pagination?.length === 0 || pagination?.first}
              disableNext={
                pagination?.length === 0 || pagination?.last || loading
              }
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "5px",
            }}
          >
            <Typography style={{ color: "#8E8E8E" }}>
              Showing {start} to {end} of {total}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
