import { Button, Grid, Typography, styled } from "@mui/material";
import {
  ButtonText,
  MainView,
  PageComponent,
  PageSize,
  SearchField,
  StandardButton,
} from "../../../components";
import { UploadTable } from "../../../components/table/UploadTable";
import { useUploadContext } from "../../../context/UploadContext";
import { useAlert, useForm } from "../../../utilities";
import { useEffect, useState } from "react";
import { UploadFilter } from "./upload-filter/UploadFilter";
import { ConfirmationDialog } from "../../../components";
import { RESET_INITIAL_STATE, API_PENDING } from "../../../constants";
import { UploadFile } from "./upload-file/UploadFile";

const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: "1%",
  padding: "1% 3% 0 3%",
  justifyContent: "space-between",
  [theme.breakpoints.down('sm')]: {
    padding: "1% 1% 0 1%",
  },
}));

const FirstColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    width: "100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
  "& > *": {
    flex: "1 1 auto", 
    minWidth: "100px", 
  },
}));

const SecondColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    alignItems: "stretch",
    gap: theme.spacing(0.5),
  },
}));

export const UploadMaintenance = () => {
  const {
    uploads,
    uploadsPageNumber,
    uploadsPageSize,
    searchKeyword,
    type,
    optimizedSearchUploads,
    mutateUploads,
    dispatchUploads,
    uploadState,
    sort,
    actions: { deleteFile, uploadFile },
  } = useUploadContext();

  const [sorting, setSorting] = useState({ column: "", order: "" });
  const { handleAlertMessage, ...alertRest } = useAlert();

  const handleSuccess = () => {
    mutateUploads();
    handleAlertMessage(uploadState.successMessage, "success");
    dispatchUploads({ type: RESET_INITIAL_STATE });
    handleCloseUpload();
  };

  const handleError = () => {
    mutateUploads();
    handleAlertMessage(uploadState.errorMessage, "error");
    dispatchUploads({ type: RESET_INITIAL_STATE });
  };

  useEffect(() => {
    if (uploadState.success) {
      handleSuccess();
    } else if (uploadState.error) {
      handleError();
    }
  }, [uploadState.successMessage, uploadState.errorMessage, uploadState]);

  const {
    showModal: showConfirmationModal,
    formData: fileData,
    handleDisplayData: handleDisplayConfirmation,
    handleCloseModal: handleCloseConfirmationModal,
  } = useForm();

  const {
    showModal: showFilter,
    handleCloseModal: handleCloseFilter,
    handleAddData: handleShowFilter,
  } = useForm();

  const {
    showModal: showUpload,
    formData: fileUploadData,
    handleCloseModal: handleCloseUpload,
    handleAddData: handleShowUpload,
  } = useForm();

  const handleDelete = () => {
    deleteFile(fileData.name);
    handleCloseConfirmationModal();
  };

  const handleSubmitFilter = (type) => {
    optimizedSearchUploads(searchKeyword, 1, uploadsPageSize, type, sort);
    handleCloseFilter();
  };

  const handleSearchKeyword = (keyword) => {
    optimizedSearchUploads(keyword, 1, uploadsPageSize, type, sort);
  };

  const handleChangePage = (pageNumber) => {
    dispatchUploads({type: API_PENDING})
    optimizedSearchUploads(
      searchKeyword,
      pageNumber,
      uploadsPageSize,
      type,
      sort
    );
  };

  const handleChangePageSize = (pageSize) => {
    optimizedSearchUploads(searchKeyword, 1, pageSize, type, sort);
  };

  const handleChangeSort = (column) => {
    const newOrder =
      sorting.column === column && sorting.order === "ASC" ? "DESC" : "ASC";

    setSorting({ column: column, order: newOrder });
    const sortValue = `${column},${newOrder}`;

    optimizedSearchUploads(
      searchKeyword,
      uploadsPageNumber,
      uploadsPageSize,
      type,
      sortValue
    );
  };

  const handleClearFilter = () => {
    optimizedSearchUploads("", uploadsPageNumber, uploadsPageSize, "ALL", sort);
  };

  const handleSubmitUpload = (values) => {
    uploadFile(values);
  };

  return (
    <MainView>
      <PageComponent
        showAlert={alertRest.showAlert}
        handleCloseAlert={alertRest.handleCloseAlert}
        alertSeverity={alertRest.alertSeverity}
        alertMessage={alertRest.alertMessage}
        isLoading={uploadState.loading}
      >
        <GridContainer container gap={2}>
          <FirstColumn item gap={2}>
            <SearchField search={handleSearchKeyword} keyword={searchKeyword} />
            <PageSize
              pageSizeValue={uploadsPageSize}
              handleChange={handleChangePageSize}
            />
            <StandardButton onClick={handleShowFilter}>
              <ButtonText>Filter</ButtonText>
            </StandardButton>
            <StandardButton onClick={handleClearFilter}>
              <ButtonText>Clear Filter</ButtonText>
            </StandardButton>
          </FirstColumn>
          <SecondColumn item gap={1}>
            <StandardButton onClick={handleShowUpload}>
              <ButtonText font>Upload</ButtonText>
            </StandardButton>
          </SecondColumn>
        </GridContainer>
        <GridContainer container>
          <UploadTable
            data={uploads?.content ?? []}
            pagination={uploads}
            uploadsPageNumber={uploadsPageNumber}
            handleChangePage={handleChangePage}
            handleSort={handleChangeSort}
            handleConfirmation={handleDisplayConfirmation}
            sorting={sorting}
          />
        </GridContainer>

        {showConfirmationModal && (
          <ConfirmationDialog
            open={showConfirmationModal}
            title={"Delete Upload"}
            handleCallBack={handleDelete}
            onClose={handleCloseConfirmationModal}
          />
        )}

        {showFilter && (
          <UploadFilter
            open={showFilter}
            onClose={handleCloseFilter}
            handleSubmitFilter={handleSubmitFilter}
            type={type}
          />
        )}

        {showUpload && (
          <UploadFile
            values={fileUploadData}
            open={showUpload}
            onClose={handleCloseUpload}
            handleSubmit={handleSubmitUpload}
            handleAlertMessage={handleAlertMessage}
            isLoading={uploadState.loading}
          />
        )}
      </PageComponent>
    </MainView>
  );
};
