import { Grid, Button, Typography, styled } from "@mui/material";
import { ButtonText, DeleteButton, MainDialog } from "../../../../components";

export const DeleteViewer = ({ open, onClose, values, handleSubmit }) => {
  return (
    <MainDialog
      title={"Confirm Deletion"}
      showDialog={open}
      handleClose={onClose}
      widthType={"sm"}
    >
      <Grid
        container
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid width={"100%"}>
          <Typography fontSize={"1.25rem"} padding={"0% 0 5% 0"}>
            Are you sure you want to remove the viewer access?
          </Typography>
        </Grid>

        <Grid width={"100%"} display={"flex"} justifyContent={"flex-end"}>
          <DeleteButton
            type="Deny"
            variant="outlined"
            onClick={() => handleSubmit({
              userId: values.requestorId,
              formId: values.formId
            })}
          >
           <ButtonText>Delete</ButtonText>
          </DeleteButton>
        </Grid>
      </Grid>
    </MainDialog>
  );
};
