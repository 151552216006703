export const Upload = {
    type: "object",
    properties: {
      textContent: {
        type: "string",
        "x-decorator": "FormItem",
        "x-component": "Input",
      },
      accept: {
        type: "string",
        "x-decorator": "FormItem",
        "x-component": "Input",
      },
      name: {
        type: "string",
        "x-decorator": "FormItem",
        "x-component": "Input",
        "x-component-props": {
          defaultValue: "file",
        },
      },
      maxCount: {
        type: "number",
        "x-decorator": "FormItem",
        "x-component": "NumberPicker",
      },
  
      multiple: {
        type: "boolean",
        "x-decorator": "FormItem",
        "x-component": "Switch",
      },
    },
  };
  