import useAxios from "../utilities/useAxios";

const BACKEND_DEV_URL = process.env.REACT_APP_BACKEND_URL;

export const updateViewer = async (data) => {
  return useAxios
    .put(`${BACKEND_DEV_URL}/form-viewer/update-status`, data)
    .catch((error) => console.error(error));
};

export const deleteViewer = async (params) => {
  return useAxios
    .delete(`${BACKEND_DEV_URL}/form-viewer/delete-form-viewer-access`, { params: params })
    .catch((error) => console.error(error));
};
