import "antd/dist/antd.less";
import React, { useMemo } from "react";
import {
  Designer,
  DesignerToolsWidget,
  ViewToolsWidget,
  Workspace,
  OutlineTreeWidget,
  ResourceWidget,
  HistoryWidget,
  StudioPanel,
  CompositePanel,
  WorkspacePanel,
  ToolbarPanel,
  ViewportPanel,
  ViewPanel,
  SettingsPanel,
  ComponentTreeWidget,
} from "@designable/react";
import { SettingsForm } from "@designable/react-settings-form";
import {
  createDesigner,
  GlobalRegistry,
  Shortcut,
  KeyCode,
} from "@designable/core";

import {
  Field,
  Input,
  Form,
  Checkbox,
  Text,
  Select,
  Radio,
  DatePicker,
  Upload,
  Image,
  FormGrid,
  Rate,
} from "./components";

import {
  LogoWidget,
  ActionsWidget,
  PreviewWidget,
  SchemaEditorWidget,
} from "./widgets";
import { FormHeader, PageComponent, SystemMessage } from "../../components";
import { useAlert } from "../../utilities";
import { saveSchema } from "./service/schema";
import { useFormEditorContext } from "../../context";

GlobalRegistry.registerDesignerLocales({
  "en-US": {
    sources: {
      Inputs: "Inputs",
      Buttons: "Buttons",
      Layouts: "Layouts",
      Arrays: "Arrays",
      Displays: "Displays",
    },
  },
});

const FormEditor = ({ children }) => {
  const { formEditorState } = useFormEditorContext();
  const engine = useMemo(
    () =>
      createDesigner({
        shortcuts: [
          new Shortcut({
            codes: [
              [KeyCode.Meta, KeyCode.S],
              [KeyCode.Control, KeyCode.S],
            ],
            handler(ctx) {
              saveSchema(ctx.engine);
            },
          }),
        ],
        rootComponentName: "Form",
      }),
    []
  );

  const { handleAlertMessage, ...alertRest } = useAlert();

  return (
    <PageComponent isLoading={formEditorState.loading}>
      <FormHeader />
      <SystemMessage
        showAlert={alertRest.showAlert}
        handleCloseAlert={alertRest.handleCloseAlert}
        alertSeverity={alertRest.alertSeverity}
        alertMessage={alertRest.alertMessage}
      />
      <Designer engine={engine}>
        {children}
        <StudioPanel
          logo={<LogoWidget />}
          actions={<ActionsWidget handleAlertMessage={handleAlertMessage} />}
          style={{ backgroundColor: "#e0e0e0", marginTop: "4%" }}
          position="relative"
        >
          <CompositePanel>
            <CompositePanel.Item title="panels.Component" icon="Component">
              <ResourceWidget
                title="sources.Inputs"
                sources={[
                  Input,
                  Checkbox,
                  Select,
                  Radio,
                  DatePicker,
                  Upload,
                  Rate,
                ]}
              />
              {/* <ResourceWidget title="sources.Buttons" sources={[]} /> */}
              <ResourceWidget title="sources.Layouts" sources={[FormGrid]} />
              <ResourceWidget
                title="sources.Displays"
                sources={[Text, Image]}
              />
            </CompositePanel.Item>
            <CompositePanel.Item title="panels.OutlinedTree" icon="Outline">
              <OutlineTreeWidget />
            </CompositePanel.Item>
            <CompositePanel.Item title="panels.History" icon="History">
              <HistoryWidget />
            </CompositePanel.Item>
          </CompositePanel>
          <Workspace id="form">
            <WorkspacePanel>
              <ToolbarPanel>
                <DesignerToolsWidget use={"HISTORY"} />
                <ViewToolsWidget use={["DESIGNABLE", "PREVIEW", "JSONTREE"]} />
              </ToolbarPanel>
              <ViewportPanel style={{ height: "100%" }}>
                <ViewPanel type="DESIGNABLE">
                  {() => (
                    <ComponentTreeWidget
                      components={{
                        Field,
                        Input,
                        Form,
                        Checkbox,
                        Text,
                        Select,
                        Radio,
                        DatePicker,
                        Upload,
                        Image,
                        FormGrid,
                        Rate,
                      }}
                    />
                  )}
                </ViewPanel>
                <ViewPanel type="JSONTREE" scrollable={false}>
                  {(tree, onChange) => (
                    <SchemaEditorWidget tree={tree} onChange={onChange} />
                  )}
                </ViewPanel>
                <ViewPanel type="PREVIEW">
                  {(tree) => <PreviewWidget tree={tree} />}
                </ViewPanel>
              </ViewportPanel>
            </WorkspacePanel>
          </Workspace>
          <SettingsPanel title="panels.PropertySettings">
            <SettingsForm uploadAction="https://www.mocky.io/v2/5cc8019d300000980a055e76" />
          </SettingsPanel>
        </StudioPanel>
      </Designer>
    </PageComponent>
  );
};

export default FormEditor;
