import MarkdownSetter from "../setters/MarkdownSetter/component";

export const Checkbox = {
  type: "object",
  properties: {
    autoFocus: {
      type: "boolean",
      "x-decorator": "FormItem",
      "x-component": "Switch",
    },
    label: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": MarkdownSetter
    },
  },
};
