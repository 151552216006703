import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Chip,
  Grid,
  IconButton,
  styled,
} from "@mui/material";
import { CustomPagination } from "../custom-pagination/CustomPagination";

import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import UnpublishedIcon from "@mui/icons-material/Unpublished";

const TableCellStyled = styled(TableCell)(({ theme }) => ({
}));

const getActionButton = (
  row,
  name,
  handleDisplayData,
  handleDisplayApproval
) => {
  if (name === "Form Builder") {
    return (
      <IconButton onClick={() => handleDisplayApproval(row)}>
        {row.status === "Published" ? (
          <UnpublishedIcon />
        ) : (
          <CheckCircleOutlineIcon />
        )}
      </IconButton>
    );
  }

  if (name === "Users") {
    return (
      <IconButton
        onClick={() => handleDisplayApproval(row)}
        disabled={row.status !== "Inactive"}
      >
        <CheckCircleOutlineIcon />
      </IconButton>
    );
  }

  return null;
};

export const FormBuilderTable = ({
  columns,
  data,
  handleDisplayData,
  handleDisplayApproval,
  handleStatus,
  name,
}) => {
  const [pageNumber, setPageNumber] = useState(1);

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const handlePreviousPage = () => {
    setPageNumber(pageNumber - 1);
  };

  return (
    <Paper
      sx={{
        margin: "0 3%",
        width: "100%",
        height: "100%",
        maxHeight: "560px",
        alignItems: "center",
        overflowX: "auto",
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCellStyled key={column.id}>
                  {column.label}
                </TableCellStyled>
              ))}
              <TableCellStyled sx={{ textAlign: "center" }}>
                Action
              </TableCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow key={row.id}>
                {columns.map((column, index) =>
                  column.id !== "status" ? (
                    <TableCellStyled key={column.id}>
                      {row[column.id]}
                    </TableCellStyled>
                  ) : (
                    <TableCellStyled key={column.id}>
                      <Chip
                        color={handleStatus(row[column.id])}
                        sx={{ color: "#fff", fontWeight: "bold" }}
                        label={row[column.id]}
                      />
                    </TableCellStyled>
                  )
                )}
                <TableCellStyled>
                  <Grid container gap={0.5} justifyContent="center">
                    <IconButton onClick={() => handleDisplayData(row)}>
                      <EditIcon />
                    </IconButton>

                    {getActionButton(
                      row,
                      name,
                      handleDisplayData,
                      handleDisplayApproval
                    )}
                  </Grid>
                </TableCellStyled>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        pageNumber={pageNumber}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
      />
    </Paper>
  );
};
