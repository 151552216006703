import { Field, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { FormBuilderValidationSchema } from "../../constants";
import { MainDialog } from "../dialog/MainDialog";
import { FormikTouched } from "../../utilities";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ModifiedSelectTag } from "../custom-select/ModifiedSelectTag";
import { useFormEditorContext, useGlobalContext } from "../../context";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { StandardButton, ButtonText } from "../styles/StandardButton";

export const ModifiedFormBuilder = ({
  innerRef,
  values,
  handleSubmit,
  handleAlertMessage,
  open,
  onClose,
  validateRef,
  setTouchedRef,
  usersList,
}) => {
  const { sectionsData, tagsData } = useGlobalContext();
  const getSections = JSON?.parse(localStorage.getItem("sections")).map(
    (item) => item.section
  );

  const [expandAccordion, setExpandAccordion] = useState(false);

  const formNameRef = useRef(null);
  const tagRef = useRef(null);
  const descriptionRef = useRef(null);
  const sectionOwnerRef = useRef(null);
  const filerSectionRef = useRef(null);
  const viewerAccessRef = useRef(null);
  const filerRef = useRef(null);
  const remarksRef = useRef(null);

  const { formEditorState } = useFormEditorContext();

  return (
    <Formik
      innerRef={innerRef}
      initialValues={{
        ...values,
        tags: values?.tags ?? [],
        sectionOwner: values?.sectionOwner
          ? values?.sectionOwner
          : getSections[0],
        successHeader: values?.formId ? values?.successHeader : "Success",
        successMessage: values?.formId
          ? values?.successMessage
          : "You submission has been recorded",
      }}
      onSubmit={handleSubmit}
      validationSchema={FormBuilderValidationSchema}
    >
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        isValid,
        setTouched,
        touched,
        handleBlur,
        validateForm,
      }) => {
        if (validateRef) validateRef.current = validateForm;
        if (setTouchedRef) setTouchedRef.current = setTouched;

        return (
          <MainDialog
            title={values?.formId ? "Update Form " : "Create Form"}
            showDialog={open}
            handleClose={onClose}
            widthType={"sm"}
          >
            <FormikTouched />
            <Grid container rowSpacing={2} paddingX={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  id="formName"
                  name="formName"
                  type="text"
                  label="Form Name"
                  value={values?.formName ?? ""}
                  required
                  fullWidth
                  size="small"
                  onChange={handleChange}
                  ref={formNameRef}
                  helperText={touched.formName && errors.formName}
                  error={Boolean(errors.formName && touched.formName)}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Field
                  id="tags"
                  name="tags"
                  value={values?.tags}
                  component={ModifiedSelectTag}
                  ref={tagRef}
                  data={tagsData}
                  helperText={touched.tags && errors.tags}
                  error={Boolean(errors.tags && touched.tags)}
                  handleBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  id="description"
                  name="description"
                  type="text"
                  multiline
                  fullWidth
                  required
                  label="Description"
                  rows={3}
                  size="small"
                  variant="outlined"
                  value={values?.description ?? ""}
                  onChange={handleChange}
                  ref={descriptionRef}
                  helperText={touched.description && errors.description}
                  error={Boolean(errors.description && touched.description)}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="sectionOwner"
                  name="sectionOwner"
                  disableClearable
                  value={values?.sectionOwner ?? ""}
                  label="Section Owner"
                  options={getSections || []}
                  getOptionLabel={(option) => option?.name}
                  onChange={(e, newValue) => {
                    setFieldValue("sectionOwner", newValue);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  disabled={getSections?.length === 1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      ref={sectionOwnerRef}
                      helperText={touched.sectionOwner && errors.sectionOwner}
                      error={Boolean(
                        errors.sectionOwner && touched.sectionOwner
                      )}
                      onBlur={handleBlur}
                      label="Section Owner"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Autocomplete
                  multiple
                  id="filerSections"
                  name="filerSections"
                  value={values?.filerSections ?? []}
                  options={
                    sectionsData
                      ?.slice()
                      ?.sort((a, b) => a.name.localeCompare(b.name)) || []
                  }
                  getOptionLabel={(option) => option?.name}
                  onChange={(e, newValue) => {
                    setFieldValue("filerSections", newValue);
                  }}
                  getOptionDisabled={(option) => {
                    if (
                      option.name !== "ALL" &&
                      values?.filerSections?.filter(
                        (item) => item.name === "ALL"
                      )?.length > 0
                    ) {
                      return true;
                    }
                    if (
                      option.name === "ALL" &&
                      values?.filerSections?.filter(
                        (item) => item.name !== "ALL"
                      )?.length > 0
                    ) {
                      return true;
                    }
                    return false;
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      ref={filerSectionRef}
                      required
                      helperText={touched.filerSections && errors.filerSections}
                      error={Boolean(
                        errors.filerSections && touched.filerSections
                      )}
                      onBlur={handleBlur}
                      label="Filer Section"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Autocomplete
                  multiple
                  id="filers"
                  name="filers"
                  value={values?.filers ?? []}
                  options={usersList || []}
                  // options={[...new Set(usersList.map((item) => item))]}
                  onChange={(e, newValue) => {
                    setFieldValue("filers", newValue);
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return (
                      option?.employeeId === value?.employeeId &&
                      option?.id === value?.id
                    );
                  }}
                  getOptionLabel={(option) => option?.fullname || ""}
                  renderOption={(props, option) => (
                    <li {...props} key={option.employeeId}>
                      {option.fullname}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      shrink
                      size="small"
                      ref={filerRef}
                      required
                      helperText={touched.filers && errors.filers}
                      error={Boolean(errors.filers && touched.filers)}
                      onBlur={handleBlur}
                      label="Filer"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Autocomplete
                  multiple
                  id="viewers"
                  name="viewers"
                  value={values?.viewers ?? []}
                  options={usersList || []}
                  onChange={(e, newValue) => {
                    setFieldValue("viewers", newValue);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  getOptionLabel={(option) => option?.fullname || ""}
                  renderOption={(props, option) => (
                    <li {...props} key={option.employeeId}>
                      {option.fullname}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      shrink
                      size="small"
                      ref={viewerAccessRef}
                      onBlur={handleBlur}
                      label="Viewer Access"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  id="remarks"
                  name="remarks"
                  type="text"
                  multiline
                  label="Remarks"
                  fullWidth
                  rows={3}
                  size="small"
                  variant="outlined"
                  value={values?.remarks ?? ""}
                  onChange={handleChange}
                  ref={remarksRef}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid container marginTop={"5%"} justifyContent={"center"}>
                <Accordion
                  expanded={expandAccordion}
                  onChange={() => {
                    setExpandAccordion(!expandAccordion);
                  }}
                  sx={{ width: "100%" }}
                >
                  <AccordionSummary
                    expandIcon={<ArrowForwardIosSharpIcon fontSize="0.9rem" />}
                    sx={{
                      flexDirection: "row-reverse",
                      gap: 2,
                    }}
                  >
                    <Typography>Customize Success Message</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ textAlign: "center" }}>
                    <CheckCircleOutlineIcon
                      sx={{ color: "#379F86", fontSize: "6rem" }}
                    />
                    <Grid>
                      <TextField
                        id="successHeader"
                        name="successHeader"
                        type="text"
                        multiline
                        value={values?.successHeader}
                        label="Title"
                        onChange={handleChange}
                        variant="standard"
                        sx={{ width: "80%" }}
                        size="small"
                      />
                    </Grid>
                    <Grid marginTop={"5%"}>
                      <TextField
                        id="successMessage"
                        name="successMessage"
                        type="text"
                        multiline
                        value={values?.successMessage}
                        label="Message"
                        onChange={handleChange}
                        variant="standard"
                        sx={{ width: "80%" }}
                        size="small"
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid container marginTop={"3%"} justifyContent="flex-end">
                <StandardButton
                  type="submit"
                  size="small"
                  disabled={!isValid}
                  onClick={(e) => {
                    isValid
                      ? handleSubmit(e)
                      : handleAlertMessage(
                          "Please resolve all fields",
                          "error"
                        );
                  }}
                  style={{ minWidth: "90px" }}
                >
                  {formEditorState.loading ? (
                    <CircularProgress size={25} sx={{ color: "white" }} />
                  ) : (
                    <ButtonText>
                      {values?.formId ? "Update" : "Create"}
                    </ButtonText>
                  )}
                </StandardButton>
              </Grid>
            </Grid>
          </MainDialog>
        );
      }}
    </Formik>
  );
};
