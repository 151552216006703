import { usePrefix, useTheme } from "@designable/react";
import { observer } from "@formily/react";
import { Button, Input, Modal } from "antd";
import React, { useState } from "react";
import cls from "classnames";
import "./styles.less";
import { useMemo } from "react";
import { observable } from "@formily/reactive";
import ReactMarkdown from "react-markdown";
import { Typography } from "antd";

const { Title } = Typography;

const Header = ({ title }) => {
  const prefix = usePrefix("data-source-setter");

  return (
    <div className={`${prefix + "-layout-item-header"}`}>
      <div className={`${prefix + "-layout-item-title"}`}>{title}</div>
    </div>
  );
};

const MarkdownSetter = observer((props) => {
  const { className, value = "", onChange } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  const theme = useTheme();
  const prefix = usePrefix("markdown-setter");
  const rawValue = useMemo(
    () =>
      observable({
        raw: value,
      }),
    [value, modalVisible]
  );

  return (
    <React.Fragment>
      <Button block onClick={openModal}>
        Edit
      </Button>
      <Modal
        title="Edit Label"
        width="65%"
        bodyStyle={{ padding: 10 }}
        transitionName=""
        maskTransitionName=""
        open={modalVisible}
        onCancel={closeModal}
        onOk={() => {
          onChange(rawValue.raw);
          closeModal();
        }}
      >
        <div
          className={`${cls(prefix, className)} ${prefix + "-" + theme} ${
            prefix + "-layout"
          }`}
        >
          <div className={`${prefix + "-layout-item left"}`}>
            <Header title="Edit here" />
            <div className={`${prefix + "-layout-item-content"}`}>
              <Input.TextArea
                value={rawValue.raw}
                onChange={(e) => {
                  rawValue.raw = e.target.value;
                }}
                placeholder="Edit here"
                bordered={false}
                styles={{ outerHeight: "100%", innerHeight: "100%" }}
                autoSize
              />
            </div>
          </div>
          <div className={`${prefix + "-layout-item right"}`}>
            <Header title="Preview" />
            <div className={`${prefix + "-layout-item-content"}`}>
              <ReactMarkdown children={rawValue.raw} />
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
});

export default MarkdownSetter;
