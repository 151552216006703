import { Button, Grid, Typography, styled } from "@mui/material";

import {
  ConfirmationDialog,
  DashboardTable,
  FormBuilderCard,
  MainView,
  PageComponent,
  PageSize,
  SearchField,
  StandardButton, 
  ButtonText,
} from "../../components";
import { useNavigate } from "react-router-dom";
import { RESET_INITIAL_STATE, API_PENDING, handleFormBuilderStatus } from "../../constants";
import { useAlert, useForm } from "../../utilities";
import { DashboardForm } from "./dashboard-form/DashboardForm";
import { useDashboardContext, useGlobalContext } from "../../context";
import { DashboardFilter } from "./dashboard-filter/DashboardFilter";
import { useEffect, useState } from "react";

const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: "1%",
  padding: "1% 3% 0 3%",
  justifyContent: "space-between",
  [theme.breakpoints.down('sm')]: {
    padding: "1% 1% 0 1%",
  },
}));

const FirstColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    width: "100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
  "& > *": {
    flex: "1 1 auto", 
    minWidth: "100px", 
  },
}));

const SecondColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    alignItems: "stretch",
    gap: theme.spacing(0.5),
  },
}));

const getColorBasedOnName = (name) => {
  switch (name) {
    case "Total Forms":
      return "#34516F";
    case "Published":
      return "#379f86";
    case "Draft":
      return "#FAAA4C";
    case "Unpublished":
      return "#D14B56";
    default:
      return "defaultColor";
  }
};

export const Dashboard = () => {
  let navigate = useNavigate();

  const {
    forms,
    formsPageNumber,
    formsPageSize,
    searchKeyword,
    formStatus,
    dateCreated,
    dispatchDashboard,
    optimizedSearchForms,
    dashboardState,
    mutateForms,
    actions: { updateStatus },
    dashboardCardsData,
    mutateDashboardCardsData,
    sort,
    userSectionsData,
    formSection,
  } = useDashboardContext();

  const [sorting, setSorting] = useState({ column: "", order: "" });

  const FormBuilderCardData = [
    { id: 1, name: "Total Forms", value: dashboardCardsData?.totalForms },
    { id: 2, name: "Published", value: dashboardCardsData?.Published },
    { id: 3, name: "Draft", value: dashboardCardsData?.Draft },
    { id: 4, name: "Unpublished", value: dashboardCardsData?.Unpublished },
  ];

  const handleSuccess = () => {
    mutateForms();
    mutateDashboardCardsData();
    handleAlertMessage(dashboardState.successMessage, "success");
    handleCloseModal();
    handleCloseConfirmationModal();
    dispatchDashboard({ type: RESET_INITIAL_STATE });
  };

  const handleError = () => {
    mutateForms();
    handleAlertMessage(dashboardState.errorMessage, "error");
    handleCloseModal();
    handleCloseConfirmationModal();
    dispatchDashboard({ type: RESET_INITIAL_STATE });
  };

  useEffect(() => {
    if (dashboardState.successMessage) {
      handleSuccess();
    } else if (dashboardState.errorMessage) {
      handleError();
    }
  }, [dashboardState.success, dashboardState.error]);

  const { formData, showModal, handleCloseModal, handleDisplayData } =
    useForm();

  const { setFormDataSchema } = useGlobalContext();

  const {
    formData: confirmationData,
    showModal: showConfirmationModal,
    handleDisplayData: handleDisplayConfirmation,
    handleCloseModal: handleCloseConfirmationModal,
  } = useForm();

  const {
    showModal: showFilter,
    handleCloseModal: handleCloseFilter,
    handleAddData: handleShowFilter,
  } = useForm();

  const { handleAlertMessage, ...alertRest } = useAlert();

  const handleDisplayFormSchema = (data) => {
    setFormDataSchema(data);
    localStorage.setItem("sectionOwner", data?.sectionOwner?.id);
    navigate("/form-builder");
  };

  const handleApprove = () => {
    updateStatus(confirmationData);
    handleCloseConfirmationModal();
  };

  const handleUpdate = () => {
    handleCloseModal();
    handleAlertMessage("Successfully Updated Data", "success");
  };

  const handleCreateFormSchema = (data) => {
    setFormDataSchema({});
    navigate("/form-builder");
  };

  const handleSubmitFilter = (status, dateCreated, formSection) => {
    optimizedSearchForms(
      searchKeyword,
      1,
      formsPageSize,
      status,
      dateCreated,
      sort,
      formSection
    );
    handleCloseFilter();
  };

  const handleSearchKeyword = (keyword) => {
    optimizedSearchForms(
      keyword,
      1,
      formsPageSize,
      formStatus,
      dateCreated,
      sort,
      formSection
    );
  };

  const handleChangePage = (pageNumber) => {
    dispatchDashboard({type: API_PENDING})
    optimizedSearchForms(
      searchKeyword,
      pageNumber,
      formsPageSize,
      formStatus,
      dateCreated,
      sort,
      formSection
    );
  };

  const handleChangePageSize = (pageSize) => {
    optimizedSearchForms(
      searchKeyword,
      1,
      pageSize,
      formStatus,
      dateCreated,
      sort,
      formSection
    );
  };

  const handleChangeSort = (column) => {
    const newOrder =
      sorting.column === column && sorting.order === "ASC" ? "DESC" : "ASC";

    setSorting({ column: column, order: newOrder });
    const sortValue = `${column},${newOrder}`;

    optimizedSearchForms(
      searchKeyword,
      formsPageNumber,
      formsPageSize,
      formStatus,
      dateCreated,
      sortValue,
      formSection
    );
  };

  const handleClearFilter = () => {
    optimizedSearchForms(
      "",
      formsPageNumber,
      formsPageSize,
      "ALL",
      "",
      sort,
      "ALL"
    );
  };

  return (
    <MainView>
      <PageComponent
        showAlert={alertRest.showAlert}
        handleCloseAlert={alertRest.handleCloseAlert}
        alertSeverity={alertRest.alertSeverity}
        alertMessage={alertRest.alertMessage}
        isLoading={dashboardState.loading}
      >
        <FormBuilderCard
          data={FormBuilderCardData}
          getColorBasedOnName={getColorBasedOnName}
        />
        <GridContainer container gap={2}>
          <FirstColumn item gap={2}>
            <SearchField search={handleSearchKeyword} keyword={searchKeyword} />
            <PageSize
              pageSizeValue={formsPageSize}
              handleChange={handleChangePageSize}
            />
             <StandardButton onClick={handleShowFilter}>
              <ButtonText>Filter</ButtonText>
            </StandardButton>
            <StandardButton onClick={handleClearFilter}>
              <ButtonText>Clear Filter</ButtonText>
            </StandardButton>
          </FirstColumn>
          <SecondColumn item gap={1}>
            <StandardButton onClick={handleCreateFormSchema}>
              <ButtonText font>Create New Form</ButtonText>
            </StandardButton>
          </SecondColumn>
        </GridContainer>
        <Grid container>
          <DashboardTable
            data={forms?.content ?? []}
            handleDisplayData={handleDisplayFormSchema}
            handleStatus={handleFormBuilderStatus}
            formsPageNumber={formsPageNumber}
            pagination={forms}
            handleChangePage={handleChangePage}
            handleDisplayConfirmation={handleDisplayConfirmation}
            loading={dashboardState.loading}
            handleSort={handleChangeSort}
            sorting={sorting}
          />
        </Grid>

        {formData && (
          <DashboardForm
            open={showModal}
            onClose={handleCloseModal}
            values={formData}
            handleSubmit={handleUpdate}
            handleAlertMessage={handleAlertMessage}
          />
        )}

        {showConfirmationModal && (
          <ConfirmationDialog
            open={showConfirmationModal}
            title={
              confirmationData.formStatus === 1
                ? "Publish Form"
                : "Unpublish Form"
            }
            handleCallBack={handleApprove}
            onClose={handleCloseConfirmationModal}
          />
        )}

        {showFilter && (
          <DashboardFilter
            open={showFilter}
            onClose={handleCloseFilter}
            handleSubmitFilter={handleSubmitFilter}
            formStatus={formStatus}
            dateCreated={dateCreated}
            formSection={formSection}
            sections={userSectionsData}
          />
        )}
      </PageComponent>
    </MainView>
  );
};
