export const FormGrid = {
  type: "object",
  properties: {
    minWidth: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": "NumberPicker",
      "x-component-props": {
        defaultValue: 100,
      },
    },
    maxWidth: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": "NumberPicker",
    },
    minColumns: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": "NumberPicker",
      "x-component-props": {
        defaultValue: 0,
      },
    },
    maxColumns: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": "NumberPicker",
    },
    breakpoints: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": "ValueInput",
      "x-component-props": {
        include: ["EXPRESSION"],
      },
    },
    columnGap: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": "NumberPicker",
      "x-component-props": {
        defaultValue: 10,
      },
    },
    rowGap: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": "NumberPicker",
      "x-component-props": {
        defaultValue: 5,
      },
    },
    colWrap: {
      type: "boolean",
      "x-decorator": "FormItem",
      "x-component": "Switch",
      "x-component-props": {
        defaultChecked: true,
      },
    },
  },
};

FormGrid.GridColumn = {
  type: "object",
  properties: {
    gridSpan: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": "NumberPicker",
      "x-component-props": {
        defaultValue: 1,
      },
    },
  },
};
