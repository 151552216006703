import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
  useReducer,
} from "react";
import {
  formEditorReducer,
  formEditorInitialState,
  formEditorActions,
} from "../reducer";
import { useUserListData } from "../utilities";
import { RESET_INITIAL_STATE } from "../constants";

const FormEditorContext = createContext();

export const useFormEditorContext = () => {
  return useContext(FormEditorContext);
};

export const FormEditorContextProvider = ({ children }) => {
  const { usersData, mutateUsersData } = useUserListData("users/list-users");

  const usersList = usersData
    ?.map(({ id, employeeId, fullname }) => ({
      id,
      employeeId,
      fullname,
    }))
    ?.sort((a, b) => a.fullname.localeCompare(b.fullname));

  const [formEditorState, dispatchFormEditor] = useReducer(formEditorReducer, {
    ...formEditorInitialState,
  });

  const [actions, setActions] = useState({});
  const stateRef = useRef();

  useEffect(() => {
    mutateUsersData();
    dispatchFormEditor({ type: RESET_INITIAL_STATE });
  }, []);

  useEffect(() => {
    Object.keys(formEditorActions).forEach((key) => {
      setActions((curr) => ({
        ...curr,
        [key]: (...args) => {
          formEditorActions[key](...args)(dispatchFormEditor, stateRef.current);
        },
      }));
    });
  }, []);

  useEffect(() => {
    stateRef.current = formEditorState;
  }, [formEditorState]);

  const value = useMemo(
    () => ({
      formEditorState,
      dispatchFormEditor,
      actions,
      usersList,
    }),
    [formEditorState, dispatchFormEditor, actions, usersList]
  );

  return (
    <FormEditorContext.Provider value={value}>
      {children}
    </FormEditorContext.Provider>
  );
};
