export const Input = {
  "en-US": {
    title: "Input",
    settings: {
      "x-component-props": {
        addonAfter: "Addon After",
        addonBefore: "Addon Before",
        maxLength: "Max Length",
        prefix: "Prefix",
        suffix: "Suffix",
        autoSize: "Auto Size",
        showCount: "Show Count",
        checkStrength: "Check Strength",
      },
    },
  },
};
