export const Rate = {
  "en-US": {
    title: "Rate",
    settings: {
      "x-component-props": {
        allowHalf: "Allow Half",
        tooltips: { title: "Tooltips", tooltip: "Format：string[]" },
        count: "Count",
      },
    },
  },
};
