import { CssBaseline, ThemeProvider } from "@mui/material";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "./constants";
import { theme } from "./utilities";
import { GlobalContextProvider } from "./context";
import { Footer, PageWrapper } from "./components";

function App() {
  return (
    <CssBaseline>
      <div className="App">
        <Router>
          <GlobalContextProvider>
            <PageWrapper>
              <ThemeProvider theme={theme}>
                <AppRoutes />
              </ThemeProvider>
              {/* <Footer /> */}
            </PageWrapper>
          </GlobalContextProvider>
        </Router>
      </div>
    </CssBaseline>
  );
}

export default App;
