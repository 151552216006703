import {
    createContext,
    useContext,
    useState,
    useMemo,
    useEffect,
    useRef,
    useReducer,
  } from "react";

import { useUploadsData } from "../utilities";
import { uploadInitialState, uploadReducer, uploadActions } from "../reducer/UploadReducer";
import { RESET_INITIAL_STATE } from "../constants";

const UploadContext = createContext();

export const useUploadContext = () => {
    return useContext(UploadContext);
};

export const UploadContextProvider = ({ children }) => {
    const {
        uploadsData,
        uploads,
        uploadsPageNumber,
        uploadsPageSize,
        searchKeyword,
        type,
        optimizedSearchUploads,
        mutateUploads,
        sort
    } = useUploadsData("files/search?");

    const [ uploadState, dispatchUploads] = useReducer(uploadReducer, {
      ...uploadInitialState,
      pageUploads: uploads,
    });

    const [actions, setActions] = useState({})  
    const stateRef = useRef();

    useEffect(() => {
      mutateUploads();
      dispatchUploads({type: RESET_INITIAL_STATE})
    }, [
        uploadsPageNumber,
        uploadsPageSize,
        searchKeyword,
        type,
        sort
    ]);

    useEffect(() => {
      Object.keys(uploadActions).forEach((key) => {
        setActions((curr) => ({
          ...curr,
          [key]: (...args) => {
            uploadActions[key](...args)(dispatchUploads, stateRef.current);
          },
        }));
      });
    }, []);

    useEffect(() => {
      stateRef.current = uploadState;
    }, [uploadState]);

    const value = useMemo(
      () => ({
        uploadsData,
        uploads,
        uploadsPageNumber,
        uploadsPageSize,
        searchKeyword,
        type,
        uploadState,
        optimizedSearchUploads,
        mutateUploads,
        dispatchUploads,
        sort,
        actions
      }),
      [
        uploadsData,
        uploads,
        uploadsPageNumber,
        uploadsPageSize,
        searchKeyword,
        type,
        uploadState,
        optimizedSearchUploads,
        mutateUploads,
        dispatchUploads,
        sort,
        actions
      ]
    );  

    return <UploadContext.Provider value={value}>{children}</UploadContext.Provider>
};