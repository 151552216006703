import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Grid, styled } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const DialogContainer = styled(Dialog)(() => ({
  display: "flex",
  justifyContent: "center",
}));

export const MessageDialog = ({
  open,
  onClose,
  autoHideDuration,
  title,
  content,
}) => {
  const [visible, setVisible] = useState(open);

  useEffect(() => {
    if (open) {
      setVisible(true);
      const timerId = setTimeout(() => {
        setVisible(false);
        onClose();
      }, autoHideDuration);

      return () => clearTimeout(timerId);
    }
  }, [open, autoHideDuration, onClose]);

  return (
    <DialogContainer open={visible} onClose={onClose}>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          height="220px"
          width="200px"
          padding="5% 2%"
          borderRadius="8px"
          rowGap={0}
        >
          <Grid item xs={12}>
            <CheckCircleOutlineIcon
              sx={{ color: "#379F86", fontSize: "80px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{content}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </DialogContainer>
  );
};
