export const Text = {
  "en-US": {
    title: "Text",
    settings: {
      "x-component-props": {
        content: "Text Content",
        mode: {
          title: "Text Mode",
          dataSource: [
            "H1",
            "H2",
            "H3",
            "H4",
            "H5",
            "H6",
            "Paragraph",
            "Normal",
          ],
        },
      },
    },
  },
};
