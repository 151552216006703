import useSWR from "swr";
import useAxios from "./useAxios";
import { useState, useEffect, useCallback } from "react";

const BACKEND_DEV_URL = process.env.REACT_APP_BACKEND_URL;

export const useEmployeesData = (url) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`);
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const { data: employeesData } = useSWR(url, fetcher);

  return { employeesData };
};

export const useSectionsData = (url) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`);
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const { data: sectionsData } = useSWR(url, fetcher);

  return { sectionsData };
};

export const useUserListData = (url) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`);
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };
  const { data: usersData, mutate: mutateUsersData } = useSWR(url, fetcher);

  return { usersData, mutateUsersData };
};

export const useSectionUserListData = (url) => {
  const [usersData, setUsersData] = useState(null);
  const fetcher = async () => {
    try {
      const response = await useAxios
        .get(`${BACKEND_DEV_URL}/${url}`)
        .then((res) => res.data)
        .then((json) => setUsersData(json?.object ?? []));
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };
  const { data: users, mutate: mutateUserSectionsData } = useSWR(
    url,
    fetcher
  );

  return { usersData, mutateUserSectionsData };
};

export const useFormsData = (url) => {
  const [formsData, setFormsData] = useState([]);
  const [formsPageNumber, setFormsPageNumber] = useState(1);
  const [formsPageSize, setFormsPageSize] = useState(10);
  const [formStatus, setFormStatus] = useState("ALL");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [sort, setSorting] = useState("dateModified,DESC");
  const [formSection, setFormSection] = useState("ALL");

  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`, {
        params: {
          page: formsPageNumber,
          size: formsPageSize,
          keyword: searchKeyword,
          statusFilter: formStatus,
          dateCreated: dateCreated,
          sort: sort,
          section: formSection,
        },
      });
      return response.data.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (
    keyword,
    pageNumber,
    pageSize,
    status,
    dateCreated,
    sorting,
    sectionType
  ) => {
    setFormsPageNumber(pageNumber);
    setFormsPageSize(pageSize);
    setSearchKeyword(keyword);
    setFormStatus(status);
    setDateCreated(dateCreated);
    setSorting(sorting);
    setFormSection(sectionType);

    useAxios
      .get(`${BACKEND_DEV_URL}/${url}`, {
        params: {
          page: pageNumber,
          size: pageSize,
          keyword: keyword,
          statusFilter: status,
          dateCreated: dateCreated,
          sort: sorting,
          section: sectionType,
        },
      })
      .then((res) => res.data)
      .then((json) => setFormsData(json?.object ?? []));
  };

  const optimizedSearchForms = useCallback(debounce(handleChange), []);

  const { data: forms, mutate: mutateForms, error } = useSWR(`${url}`, fetcher);

  return {
    formsData,
    forms,
    formsPageNumber,
    formsPageSize,
    formStatus,
    searchKeyword,
    dateCreated,
    sort,
    optimizedSearchForms,
    mutateForms,
    error,
    formSection,
  };
};

export const useUsersData = (url) => {
  const [usersData, setUsersData] = useState([]);
  const [usersPageNumber, setUsersPageNumber] = useState(1);
  const [usersPageSize, setUsersPageSize] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [userStatus, setUserStatus] = useState("ALL");
  const [dateCreated, setDateCreated] = useState("");
  const [sort, setSorting] = useState("date_modified,DESC");

  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`, {
        params: {
          page: usersPageNumber,
          size: usersPageSize,
          keyword: searchKeyword,
          statusFilter: userStatus,
          dateCreated: dateCreated,
          sort: sort,
        },
      });
      return response.data.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (
    keyword,
    pageNumber,
    pageSize,
    status,
    dateCreated,
    sort
  ) => {
    setUsersPageNumber(pageNumber);
    setUsersPageSize(pageSize);
    setSearchKeyword(keyword);
    setUserStatus(status);
    setDateCreated(dateCreated);
    setSorting(sort);

    useAxios
      .get(`${BACKEND_DEV_URL}/${url}`, {
        params: {
          page: pageNumber,
          size: pageSize,
          keyword: keyword,
          statusFilter: status,
          dateCreated: dateCreated,
          sort: sort,
        },
      })
      .then((res) => res.data)
      .then((json) => setUsersData(json?.object ?? []));
  };

  const optimizedSearchUsers = useCallback(debounce(handleChange), []);

  const {
    data: users,
    mutate: mutateUsers,
    error,
  } = useSWR(`${url}`, fetcher, { revalidateOnFocus: false });

  // useEffect(() => {
  //   mutateUsers();
  // }, [
  //   usersPageNumber,
  //   usersPageSize,
  //   searchKeyword,
  //   userStatus,
  //   dateCreated,
  //   sort,
  // ]);

  return {
    usersData,
    users,
    usersPageNumber,
    usersPageSize,
    userStatus,
    searchKeyword,
    dateCreated,
    sort,
    optimizedSearchUsers,
    mutateUsers,
    error,
  };
};

export const useUserCardsData = (url) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`);
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };
  const { data: userCardsData, mutate: mutateUserCardsData } = useSWR(
    url,
    fetcher
  );

  return { userCardsData, mutateUserCardsData };
};

export const useDashboardCardsData = (url) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`);
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };
  const { data: dashboardCardsData, mutate: mutateDashboardCardsData } = useSWR(
    url,
    fetcher
  );

  return { dashboardCardsData, mutateDashboardCardsData };
};

export const useViewersData = (url) => {
  const [viewersData, setViewersData] = useState([]);
  const [viewersPageNumber, setViewersPageNumber] = useState(1);
  const [viewersPageSize, setViewersPageSize] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [viewerStatus, setViewerStatus] = useState("ALL");
  const [sort, setSorting] = useState("date_created,DESC");

  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`, {
        params: {
          page: viewersPageNumber,
          size: viewersPageSize,
          keyword: searchKeyword,
          statusFilter: viewerStatus,
          sort: sort,
        },
      });
      return response.data.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (keyword, pageNumber, pageSize, status) => {
    setViewersPageNumber(pageNumber);
    setViewersPageSize(pageSize);
    setSearchKeyword(keyword);
    setViewerStatus(status);

    useAxios
      .get(`${BACKEND_DEV_URL}/${url}`, {
        params: {
          page: pageNumber,
          size: pageSize,
          keyword: keyword,
          statusFilter: status,
          sort: sort,
        },
      })
      .then((res) => res.data)
      .then((json) => setViewersData(json?.object ?? []));
  };

  const optimizedSearchViewers = useCallback(debounce(handleChange), []);

  const {
    data: viewers,
    mutate: mutateViewers,
    error,
  } = useSWR(`${url}`, fetcher);

  // useEffect(() => {
  //   mutateViewers();
  // }, [viewersPageNumber, viewersPageSize, searchKeyword, viewerStatus]);

  return {
    viewersData,
    viewers,
    viewersPageNumber,
    viewersPageSize,
    searchKeyword,
    optimizedSearchViewers,
    mutateViewers,
    error,
    sort,
    viewerStatus,
  };
};

export const useUploadsData = (url) => {
  const [uploadsData, setUploadsData] = useState([]);
  const [uploadsPageNumber, setUploadsPageNumber] = useState(1);
  const [uploadsPageSize, setUploadsPageSize] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [type, setType] = useState("ALL");
  const [sort, setSorting] = useState("date_created,DESC");

  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`, {
        params: {
          page: uploadsPageNumber,
          size: uploadsPageSize,
          keyword: searchKeyword,
          fileType: type,
          sort: sort,
        },
      });
      return response.data.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (keyword, pageNumber, pageSize, type, sort) => {
    setUploadsPageNumber(pageNumber);
    setUploadsPageSize(pageSize);
    setSearchKeyword(keyword);
    setType(type);

    useAxios
      .get(`${BACKEND_DEV_URL}/${url}`, {
        params: {
          page: pageNumber,
          size: pageSize,
          keyword: keyword,
          fileType: type,
          sort: sort,
        },
      })
      .then((res) => res.data)
      .then((json) => setUploadsData(json?.object ?? []));
  };

  const optimizedSearchUploads = useCallback(debounce(handleChange), []);

  const {
    data: uploads,
    mutate: mutateUploads,
    error,
  } = useSWR(`${url}`, fetcher);

  return {
    uploadsData,
    uploads,
    uploadsPageNumber,
    uploadsPageSize,
    searchKeyword,
    type,
    optimizedSearchUploads,
    mutateUploads,
    error,
    sort,
  };
};

export const useGetSectionImages = (url) => {
  const fetcher = async () => {
    try {
      const sectionId = localStorage.getItem("sectionOwner");
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`);

      const filteredEnums =
        response?.data?.object
          .filter((item) => parseInt(item.sectionId) === parseInt(sectionId))
          .map((item) => ({ value: item.url, label: item.originalName })) ?? [];

      filteredEnums.push({
        value:
          "https://staging.api.yamahamotorphilippines.com.ph/uploaded-files/png/mrJbsOOOEyMhYv2.png",
        label: "Default",
      });

      return filteredEnums;
    } catch (error) {
      console.error(error);
    }
  };

  const { data: sectionImageData, mutate: mutateSectionImageData } = useSWR(
    url,
    fetcher
  );

  useEffect(() => {
    mutateSectionImageData();
  }, [localStorage.getItem("sectionOwner")]);

  return { sectionImageData, mutateSectionImageData };
};

export const useGetSectionsData = (url) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`);
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };
  const { data: userSectionsData, mutate: mutateSectionsData } = useSWR(
    url,
    fetcher
  );

  return { userSectionsData, mutateSectionsData };
};

export const useTagsData = (url) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`);

      const arrayTags =
        response?.data?.object?.map((item) => item.name)?.sort() ?? [];
      return arrayTags;
    } catch (error) {
      console.error(error);
    }
  };

  const { data: tagsData } = useSWR(url, fetcher);

  return { tagsData };
};

export const useSearchUsers = async (keyword) => {
  const [loading, setLoading] = useState(false);
  const [employeeNames, setEmployeeNames] = useState([]);

  const fetchEmployeeNames = async (inputValue) => {
    setLoading(true);
    try {
      // API call based on input value
      const response = await useAxios.get(`${BACKEND_DEV_URL}/users/search`, {
        params: {
          page: 1,
          size: 20,
          keyword: keyword,
          statusFilter: "ALL",
          dateCreated: "",
          sort: "ate_modified,DESC",
        },
      });

      // Optionally sort data if needed
      const sortedData = response?.object?.content.sort((a, b) =>
        a.fullname.localeCompare(b.fullname)
      );

      setEmployeeNames(sortedData); // Update options
    } catch (error) {
      console.error("Failed to fetch employee names", error);
    } finally {
      setLoading(false);
    }
  };
};
