export const CheckboxGroup = {
  "en-US": {
    title: "Checkbox Group",
  },
};

export const Checkbox = {
  "en-US": {
    title: "Checkbox",
    settings: {
      "x-component-props": {
        label: "Label",
      },
    },
  },
};
