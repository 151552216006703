import { createBehavior, createResource } from "@designable/core";
import { createVoidFieldSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
import ReactMarkdown from "react-markdown";
import cls from "classnames";
import "./styles.less";
import { connect, mapProps, observer } from "@formily/react";
import { useNodeIdProps } from "@designable/react";
import React from "react";

const TextComponent = (props) => {
  return(
    <div {...props}>
      <ReactMarkdown {...props}>
        {props.children}
      </ReactMarkdown>
    </div>
  )
}

export const Text = connect(
  TextComponent,
  mapProps((props, field) => ({ ...props, ...field, children: props.content }))
);

Text.Behavior = createBehavior({
  name: "Text",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Text",
  designerProps: {
    propsSchema: createVoidFieldSchema(AllSchemas.Text),
  },
  designerLocales: AllLocales.Text,
});

Text.Resource = createResource({
  icon: "TextSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "string",
        "x-component": "Text",
        "x-component-props": {
          content: "Edit this in content properties",
        },
      },
    },
  ],
});
