import { createBehavior, createResource } from "@designable/core";
import { Image as FormilyImage } from "antd";
import { createVoidFieldSchema } from "../Field";
import { connect, mapProps } from "@formily/react";
import { AllLocales } from "../../locales";
import { AllSchemas } from "../../schemas";

const ImageComponent = (props) => {
  return <FormilyImage {...props}>{props.children}</FormilyImage>;
};

export const Image = connect(
  ImageComponent,
  mapProps((props, field) => ({ ...props, ...field, children: props.content }))
);

Image.Behavior = createBehavior({
  name: "Image",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Image",
  designerProps: {
    propsSchema: createVoidFieldSchema(AllSchemas.Image),
  },
  designerLocales: AllLocales.Image,
});

Image.Resource = createResource({
  icon: "ImageSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "string",
        "x-component": "Image",
        "x-component-props": {
          height: "200px",
          width: "200px",
          preview: false,
          src: "https://staging.api.yamahamotorphilippines.com.ph/uploaded-files/png/mrJbsOOOEyMhYv2.png",
        },
      },
    },
  ],
});
