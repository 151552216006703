import useAxios from "../utilities/useAxios";

const BACKEND_DEV_URL = process.env.REACT_APP_BACKEND_URL;

export const deleteFile = async (fileName) => {
  return useAxios
    .delete(`${BACKEND_DEV_URL}/files/delete-file/${fileName}`)
    .catch((error) => console.error(error));
};

export const uploadFile = async (url, data) => {
  return await useAxios.post(url, data).catch((error) => console.error(error));
};

export const newDeleteFile = async (url, fileName) => {
  return await useAxios
    .delete(`${url}/${fileName}`)
    .catch((error) => console.error(error));
};
