import {
  createContext,
  useContext,
  useState,
  useMemo,
  useReducer,
  useEffect,
  useRef,
} from "react";
import {
  useDashboardCardsData,
  useFormsData,
  useGetSectionsData,
} from "../utilities";
import {
  dashboardReducer,
  dashboardInitialState,
  dashboardActions,
} from "../reducer";
import { RESET_INITIAL_STATE } from "../constants";

const DashboardContext = createContext();

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};

export const DashboardContextProvider = ({ children }) => {
  const {
    forms,
    formsPageNumber,
    formsPageSize,
    formStatus,
    searchKeyword,
    dateCreated,
    sort,
    optimizedSearchForms,
    mutateForms,
    formSection,
  } = useFormsData("form-designs/search?");
  

  const { dashboardCardsData, mutateDashboardCardsData } =
    useDashboardCardsData("form-designs/form-design-cards");

  const { userSectionsData, mutateSectionsData } = useGetSectionsData(
    "form-designs/sections"
  );

  const [dashboardState, dispatchDashboard] = useReducer(dashboardReducer, {
    ...dashboardInitialState,
    pageForms: forms,
  });

  useEffect(() => {
    mutateForms();
    dispatchDashboard({ type: RESET_INITIAL_STATE });
  }, [
    formsPageNumber,
    formsPageSize,
    formStatus,
    searchKeyword,
    dateCreated,
    sort,
    formSection,
  ]);

  const [actions, setActions] = useState({});
  const stateRef = useRef();

  useEffect(() => {
    Object.keys(dashboardActions).forEach((key) => {
      setActions((curr) => ({
        ...curr,
        [key]: (...args) => {
          dashboardActions[key](...args)(dispatchDashboard, stateRef.current);
        },
      }));
    });
  }, []);

  useEffect(() => {
    stateRef.current = dashboardState;
  }, [dashboardState]);

  const value = useMemo(
    () => ({
      formStatus,
      dateCreated,
      forms,
      formsPageNumber,
      formsPageSize,
      searchKeyword,
      dashboardState,
      dispatchDashboard,
      optimizedSearchForms,
      mutateForms,
      actions,
      dashboardCardsData,
      mutateDashboardCardsData,
      sort,
      userSectionsData,
      mutateSectionsData,
      formSection,
    }),
    [
      formStatus,
      dateCreated,
      forms,
      formsPageNumber,
      formsPageSize,
      searchKeyword,
      dashboardState,
      dispatchDashboard,
      optimizedSearchForms,
      mutateForms,
      actions,
      dashboardCardsData,
      mutateDashboardCardsData,
      sort,
      userSectionsData,
      mutateSectionsData,
      formSection,
    ]
  );

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};
