import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const FormDialogTitle = styled(DialogTitle)(() => ({
  padding: "2.5% 2.5%",
  background: "#1B9A92",
  color: "#fff",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const MainDialog = ({
  children,
  showDialog,
  handleClose,
  title,
  widthType,
  type,
  ...props
}) => {
  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        scroll="paper"
        PaperProps={{
          style: {
            minWidth: "35%",
            maxWidth: "35%",
            minHeight: "10%",
            maxHeight: "75%",
            overflowY: "auto",
            borderRadius: 10,
          },
        }}
      >
        <FormDialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ color: "#fff" }}
          >
            <CloseIcon />
          </IconButton>
        </FormDialogTitle>

        <DialogContent dividers={true} {...props}>
          {children}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
