import { useRef, useState } from "react";
import { FileUpload, MainDialog } from "../../../../components";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { Field, Formik } from "formik";
import { FileUploadValidationSchema } from "../../../../constants";
import { StandardButton, ButtonText } from "../../../../components";

export const UploadFile = ({
  innerRef,
  open,
  onClose,
  values,
  handleSubmit,
  validateRef,
  setTouchedRef,
  handleAlertMessage,
  isLoading
}) => {
  const getSections = JSON?.parse(localStorage.getItem("sections")).map(
    (item) => item.section
  );

  const fileRef = useRef(null);
  const sectionOwnerRef = useRef(null);

  return (
    <Formik
      innerRef={innerRef}
      initialValues={{ ...values, sectionOwner: getSections[0] }}
      onSubmit={handleSubmit}
      validationSchema={FileUploadValidationSchema}
    >
      {({
        handleSubmit,
        setFieldValue,
        values,
        errors,
        isValid,
        setTouched,
        handleBlur,
        validateForm,
      }) => {
        if (validateRef) validateRef.current = validateForm;
        if (setTouchedRef) setTouchedRef.current = setTouched;

        return (
          <MainDialog
            title={"Image Upload"}
            showDialog={open}
            handleClose={onClose}
            widthType={"xs"}
          >
            <Grid container gap={2} padding={2} justifyContent="center">
              <Grid
                item
                xs={12}
                md={12}
                rowSpacing={4}
                container
                justifyContent="center"
              >
                <Grid item xs={12} md={12}>
                  <Field
                    id="file"
                    name="file"
                    value={values?.file}
                    ref={fileRef}
                    component={FileUpload}
                    helperText={errors.file}
                    error={Boolean(errors.file)}
                    handleBlur={handleBlur}
                  />
                </Grid>

                <Grid item sx={12} md={12}>
                  <Autocomplete
                    id="sectionOwner"
                    name="sectionOwner"
                    disableClearable
                    value={values?.sectionOwner ?? ""}
                    label="Section Owner"
                    options={getSections || []}
                    getOptionLabel={(option) => option?.name}
                    onChange={(e, newValue) => {
                      setFieldValue("sectionOwner", newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    disabled={getSections.length === 1}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        ref={sectionOwnerRef}
                        helperText={errors.sectionOwner}
                        error={Boolean(errors.sectionOwner)}
                        onBlur={handleBlur}
                        label="Section Owner"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container marginTop={"3%"} justifyContent="flex-end">
                <StandardButton
                  type="submit"
                  size="small"
                  disabled={!isValid}
                  onClick={(e) => {
                    isValid
                      ? handleSubmit(e)
                      : handleAlertMessage(
                          "Please resolve all fields",
                          "error"
                        );
                  }}
                  style={{ minWidth: "90px" }}
                >
                  {isLoading ? (
                    <CircularProgress size={25} sx={{ color: "white" }} />
                  ) : (
                    <ButtonText>Submit</ButtonText>
                  )}
                </StandardButton>
              </Grid>
            </Grid>
          </MainDialog>
        );
      }}
    </Formik>
  );
};
