import React from "react";
import { useTheme } from "@designable/react";
import { Space } from "antd";

const logo = {
  dark: "/youthwise-logo-p.png",
  light: "/youthwise-logo-p.png",
};

export const LogoWidget = () => {
  const url = logo[useTheme()];
  return (
    <Space
      style={{
        // width: window.innerWidth * 0.05,
        height: 60,
        display: "flex",
        justifyContent: "flex-end",
        marginTop:"4%"
      }}
    >
      {/* <img src={url} style={{ margin: "5px 8px", height: 50, width: "auto", display:""}} /> */}
    </Space>
  );
};
