import { Grid, Button, Typography, styled } from "@mui/material";
import { MainDialog } from "../../../../components";

const ConfirmButton = styled(Button)(({ type }) => ({
  fontSize: "0.90rem",
  padding: "0.8% 3%",
  color: "#ffffff",
  textTransform: "capitalize",
  background: type === "Approve" ? "#1B9A92" : "#eb0910",
  width: "25%",
  "&:hover": {
    background: type === "Approve" ? "#1B9A92" : "#eb0910",
  },
}));

export const ViewerForm = ({ open, onClose, values, handleSubmit }) => {
  return (
    <MainDialog
      title={values.type === "Approve" ? "Confirm Approval" : "Confirm Deny"}
      showDialog={open}
      handleClose={onClose}
      widthType={"sm"}
    >
      {values.type === "Approve" ? (
        <Grid
          container
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid width={"100%"}>
            <Typography fontSize={"1.25rem"} padding={"5% 0 5% 0"}>
              Are you sure you want to approve this request?
            </Typography>
          </Grid>

          <Grid width={"100%"} display={"flex"} justifyContent={"flex-end"}>
            <ConfirmButton
              type="Approve"
              variant="outlined"
              onClick={() => handleSubmit(values.id, 2)}
            >
              Approve
            </ConfirmButton>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid width={"100%"}>
            <Typography fontSize={"1.25rem"} padding={"5% 0 5% 0"}>
              Are you sure you want to deny this request?
            </Typography>
          </Grid>

          <Grid width={"100%"} display={"flex"} justifyContent={"flex-end"}>
            <ConfirmButton
              type="Deny"
              variant="outlined"
              onClick={() => handleSubmit(values.id, 3)}
            >
              Deny
            </ConfirmButton>
          </Grid>
        </Grid>
      )}
    </MainDialog>
  );
};
