import useAxios from "../utilities/useAxios";

const BACKEND_DEV_URL = process.env.REACT_APP_BACKEND_URL;

export const addUser = async (data) => {
  return useAxios
    .post(`${BACKEND_DEV_URL}/users`, data)
    .catch((error) => console.error(error));
};

export const updateUser = async (data) => {
  return useAxios
    .put(`${BACKEND_DEV_URL}/users/sectionadmin/${data?.user.id}`, data)
    .catch((error) => console.error(error));
};
