/* eslint-disable no-throw-literal */
import { addForm, saveSchema, updateForm, updateFormStatus } from "../api";
import {
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  RESET_INITIAL_STATE,
} from "../constants";

export const formEditorInitialState = {
  loading: true,
  error: false,
  errorType: "",
  errorMessage: "",
  success: false,
  successMessage: "",
  result: {},
  resultType: "",
};

export const formEditorActions = {
  submitForm: (data) => async (dispatch) => {
    dispatch({ type: API_PENDING });

    const payload = {
      id: 0,
      formName: data.formName,
      tags: data.tags,
      description: data.description,
      sectionOwnerId: data.sectionOwner.id,
      filerSections:
        data?.filerSections?.length > 0
          ? data?.filerSections.map(({ id, name }) => ({ id, name }))
          : [],
      filers: data?.filers ?? [],
      viewers: data?.viewers ?? [],
      remarks: data.remarks,
      successHeader: data.successHeader,
      successMessage: data.successMessage,
      createdBy: localStorage.getItem("id"),
      modifiedBy: localStorage.getItem("id"),
    };

    try {
      await addForm(payload).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to add form.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
        dispatch({
          type: API_SUCCESS,
          payload: {
            message: `Successfully Added Form`,
            data: response?.data?.object,
            type: "ADD_FORM",
          },
        });
      });
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
  editForm: (data) => async (dispatch) => {
    dispatch({ type: API_PENDING });

    console.log("DATA VIEWERS", data);
    const payload = {
      id: data.formId,
      formName: data.formName,
      tags: data.tags,
      description: data.description,
      sectionOwnerId: data.sectionOwner.id,
      filerSections: data.filerSections.map(({ id, name }) => ({ id, name })),
      filers: data.filers.map(({ id, employeeId, fullname }) => ({
        id,
        employeeId,
        fullname,
      })),
      viewers:
        data?.viewers.map(({ id, employeeId, fullname }) => ({
          id,
          employeeId,
          fullname,
        })) ?? [],
      remarks: data.remarks,
      successHeader: data.successHeader,
      successMessage: data.successMessage,
      createdBy: data?.createdBy?.id,
      modifiedBy: localStorage.getItem("id"),
    };

    try {
      await updateForm(payload).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to add form.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }

        dispatch({
          type: API_SUCCESS,
          payload: {
            message: `Successfully Updated Form`,
            data: response?.data?.object,
            type: "UPDATE_FORM",
          },
        });
      });
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
  saveSchema: (data, schema) => async (dispatch) => {
    dispatch({ type: API_PENDING });

    const payload = {
      id: data.formId,
      formDesignId: data.formDesignId,
      createdAndModifiedById: localStorage.getItem("id"),
      design: schema,
    };

    try {
      await saveSchema(payload).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to add form.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }

        dispatch({
          type: API_SUCCESS,
          payload: {
            message: `Successfully Updated Form Schema`,
            data: response?.data?.object,
            type: "UPDATE_FORM_SCHEMA",
          },
        });
      });
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
  publishSchema: (data) => async (dispatch) => {
    dispatch({ type: API_PENDING });

    const payload = {
      id: data.id,
      formStatus: data.formStatus,
    };

    try {
      await updateFormStatus(payload).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to add form.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
        dispatch({
          type: API_SUCCESS,
          payload: {
            message: `Successfully Published Form Schema`,
            data: response?.data?.object,
            type: "PUBLISH_FORM_SCHEMA",
          },
        });
      });
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
  saveAndPublish: (data, publishSchema) => async (dispatch) => {
    dispatch({ type: API_PENDING });
    const payload = {
      id: data.formId,
      formDesignId: data.id,
      createdAndModifiedById: localStorage.getItem("id"),
      design: data.schema,
    };

    try {
      await saveSchema(payload).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.httpStatus == "OK") {
          const id = response?.data?.object?.id;
          publishSchema({ id: id, formStatus: 2 });
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to add form.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
      });
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
};

export const formEditorReducer = (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case API_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorType: "",
        errorMessage: "",
        success: false,
        successMessage: "",
        result: {},
      };
    case API_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorType: payload.type,
        errorMessage: payload.message,
        result: {},
      };
    case API_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingUsers: false,
        error: false,
        success: true,
        successMessage: payload.message,
        result: payload.data,
        resultType: payload.type,
      };
    case RESET_INITIAL_STATE:
      return {
        ...state,
        loading: false,
        loadingUsers: false,
        error: false,
        success: false,
        successMessage: "",
        errorMessage: "",
        result: {},
      };
    default:
      return state;
  }
};
