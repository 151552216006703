export const PageTitles = [
	{
		path: "/",
		title: "Forms Builder Management",
	},
	{
		path: "/form-builder",
		title: "Form Builder"
	},
	{
		path: "/user-maintenance",
		title: "User Management"
	},
	{
		path: "/viewer-maintenance",
		title: "Viewer Management"
	},
	{
		path: "/upload-maintenance",
		title: "Upload Management"
	}
];
