import * as Yup from "yup";

export const FormBuilderValidationSchema = Yup.object({
  formName: Yup.string().required("Form name is required"),
  // tags: Yup.array()
  //   .test(
  //     "is-empty-array",
  //     "Tags must not be an empty",
  //     (value) => value && value?.length > 0
  //   )
  //   .required("Tags must not be an empty"),
  description: Yup.string().required("Description is required"),
  sectionOwner: Yup.object().required("Section Owner is required"),
  filerSections: Yup.array()
    .test({
      name: "conditional-validation",
      message: "Filer Section must not be empty when Filers is empty",
      test: function (value) {
        const { filers } = this.parent;
        return (filers && filers.length > 0) || (value && value.length > 0);
      },
    })
    .nullable(),
  filers: Yup.array()
    .test({
      name: "conditional-validation",
      message: "Filer must not be empty when Filer Sections is empty",
      test: function (value) {
        const { filerSections } = this.parent;
        return (
          (filerSections && filerSections.length > 0) ||
          (value && value.length > 0)
        );
      },
    })
    .nullable(),
});

export const UserValidationSchema = Yup.object({
  fullname: Yup.object().required("Employee name is required"),
  sections: Yup.array()
    .test(
      "is-empty-array",
      "Section must not be an empty.",
      (value) => value && value?.length > 0
    )
    .required("Section must not be empty"),
  status: Yup.string().required("Status is required"),
});


export const FileUploadValidationSchema = Yup.object().shape({
  file: Yup.mixed().required("Image is required"),
  sectionOwner: Yup.object().required("Section Owner is required"),
})