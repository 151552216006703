import { useState } from "react";

export const useForm = () => {
  const [formData, setFormData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleAddData = () => {
    setFormData({});
    setShowModal(true); 
  };
  const handleDisplayData = (data) => {
    setFormData(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormData(null);
  };

  return {
    formData,
    showModal,
    handleCloseModal,
    setFormData,
    handleDisplayData,
    handleAddData,
  };
};
