import MarkdownSetter from "../setters/MarkdownSetter/component";

export const Text = {
  type: "object",
  properties: {
    content: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": MarkdownSetter,
    },
    mode: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "Select",
      "x-component-props": {
        defaultValue: "normal",
      },
      enum: ["h1", "h2", "h3", "h4", "h5", "h6", "p", "normal"],
    },
  },
};
