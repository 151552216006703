export const SelectEmployees = {
    "en-US": {
      name: "SelectEmployees",
      title: "Select Employees",
      settings: {
        "x-component-props": {
          mode: {
            title: "Mode",
            dataSource: ["Multiple", "Tags", "Single"],
          },
          autoClearSearchValue: {
            title: "Auto Clear Search Value",
            tooltip: "Only used to multiple and tags mode",
          },
          defaultActiveFirstOption: "Default Active First Option",
          dropdownMatchSelectWidth: "Dropdown Match Select Width",
          defaultOpen: "Default Open",
          filterOption: "Filter Option",
          filterSort: "Filter Sort",
          labelInValue: "label InValue",
          listHeight: "List Height",
          maxTagCount: "Max Tag Count",
          maxTagPlaceholder: {
            title: "Max Tag Placeholder",
            tooltip: "Content displayed when tag is hidden",
          },
          maxTagTextLength: "Max Tag Text Length",
          showArrow: "Show Arrow",
          virtual: "Use Virtual Scroll",
        },
      },
    },
  };
  