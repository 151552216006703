import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const FormDialog = styled(Dialog)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const FormDialogTitle = styled(DialogTitle)(() => ({
  padding: "2.5% 5%",
  background: "#1B9A92",
  color: "#fff",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const StyledDialogContent = styled(DialogContent)(() => ({
  maxWidth: "800px",
  maxHeight: "700px",
  overflowY: "auto",
  "&:first-child": {
    paddingTop: "3%",
  },
  "&:last-child": {
    paddingBottom: "3%",
  },
}));

export const FormsDialog = ({
  children,
  open,
  onClose,
  title,
  content,
  widthType,
  ...props
}) => {
  return (
    <FormDialog
      open={open}
      onClose={onClose}
      scroll="paper"
      fullWidth
      maxWidth={widthType}
    >
      <FormDialogTitle>
        {title}
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </FormDialogTitle>
      <StyledDialogContent dividers={true} {...props}>
        {children}
      </StyledDialogContent>
    </FormDialog>
  );
};
