import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { userSignIn } from "../../api";
import { jwtDecode } from "jwt-decode"; 
import { ErrorPage } from "../error-page/ErrorPage";

export const PageWrapper = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [notAvailable, setNotAvailable] = useState({
    unauthorized: false,
    message: "",
    code: "",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [screenText, setScreenText] = useState("");
  const navigate = useNavigate();

  const handleInvalidToken = () => {
    localStorage.clear();
    setNotAvailable({
      unauthorized: true,
      message: "USER IS UNAUTHORIZED TO ACCESS THIS PAGE",
      code: "401",
    });
  };

  const validateToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          handleInvalidToken();
        }
      } catch (e) {
        handleInvalidToken();
      }
    } else {
      handleInvalidToken();
    }
  };

  useEffect(() => {
    const fetchToken = async () => {
      if (searchParams.get("userId") && searchParams.get("code")) {
        localStorage.clear();

        const userId = searchParams.get("userId");
        const code = searchParams.get("code");
        const response = await userSignIn({ userId, code });

        if (response?.data.httpStatus === "NOT_FOUND") {
          setLoading(false);
          setNotAvailable({
            unauthorized: true,
            message: "THIS USER COULD NOT BE FOUND",
            code: "404",
          });
        } else if (response?.data?.httpStatus === "UNAUTHORIZED") {
          setLoading(false);
          setNotAvailable({
            unauthorized: true,
            message: "USER IS UNAUTHORIZED TO ACCESS THIS PAGE",
            code: "401",
          });
        } else {
          localStorage.setItem("userId", userId);
          localStorage.setItem("code", code);
          localStorage.setItem("fullName", response?.data?.object?.userName);
          localStorage.setItem("token", response?.data?.object?.jwt);
          localStorage.setItem(
            "role",
            JSON.stringify(response?.data?.object?.userRole)
          );
          localStorage.setItem(
            "sections",
            JSON.stringify(response?.data?.object?.sections)
          );
          localStorage.setItem("id", response?.data?.object?.id);

          searchParams.delete("userId");
          searchParams.delete("code");
          setLoading(false);
          setSearchParams(searchParams);
        }
      } else {
        if (localStorage.getItem("token")) {
          setLoading(false);
        }
      }
    };

    fetchToken();

    const intervalId = setInterval(validateToken, 1000);

    return () => clearInterval(intervalId);
  }, [searchParams, setSearchParams]);

  if (loading) {
    return (
      <Backdrop open={loading}>
        <Grid rowGap={2} container justifyContent="center" alignItems="center">
          <Typography>{screenText}</Typography>
          <CircularProgress color="inherit" />
        </Grid>
      </Backdrop>
    );
  } else if (notAvailable.unauthorized) {
    return <ErrorPage error={notAvailable} />;
  } else {
    return <Grid>{children}</Grid>;
  }
};