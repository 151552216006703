import useAxios from "./useAxios";

const BACKEND_DEV_URL = process.env.REACT_APP_BACKEND_URL;

export const fetchEmployees = async () => {
  try {
    const response = await useAxios.get(`${BACKEND_DEV_URL}/employees`);
    return response?.data?.object.map((employee) => ({
      label: employee?.userName,
      value: employee?.userName,
    }));
  } catch (error) {
    console.error("Error fetching employees:", error);
    throw error;
  }
};

export const fetchSections = async () => {
  try {
    const response = await useAxios.get(`${BACKEND_DEV_URL}/sections`);
    return response?.data?.object.map((employee) => ({
      label: employee?.name,
      value: employee?.name,
    }));
  } catch (error) {
    console.error("Error fetching sections:", error);
    throw error;
  }
};
