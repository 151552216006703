import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ButtonText, StandardButton } from "../styles/StandardButton";

const FormDialog = styled(Dialog)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "2.5% 5%",
}));

const FormDialogTitle = styled(DialogTitle)(() => ({
  background: "#1B9A92",
  color: "#fff",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const StyledDialogContent = styled(DialogContent)(() => ({
  maxWidth: "800px",
  width: "450px",
  maxHeight: "700px",
  overflowY: "auto",
  "&:first-child": {
    paddingTop: "3%",
  },
  "&:last-child": {
    paddingBottom: "3%",
  },
}));

const StyledDialogAction = styled(DialogActions)(() => ({
  padding: "3% 5%",
  justifyContent: "center",
}));

export const ConfirmationDialog = ({
  open,
  onClose,
  handleCallBack,
  title,
}) => {
  const [text, setText] = useState("");

  return (
    <FormDialog open={open} onClose={onClose} scroll="paper">
      <FormDialogTitle>
        {title ?? "Confirmation"}
        <IconButton aria-label="close" onClick={onClose} sx={{ color: "#fff" }}>
          <CloseIcon />
        </IconButton>
      </FormDialogTitle>
      <StyledDialogContent dividers={true}>
        <Grid container rowGap={1.5}>
          <Grid item xs={12} justifyContent="center" textAlign="center">
            <Typography>Are you sure you want to submit changes?</Typography>
          </Grid>
          <Grid item xs={12} justifyContent="center" textAlign={"center"}>
            <Typography>Type "PROCEED" to confirm action</Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <TextField
              onChange={(event) => {
                setText(event.target.value);
              }}
              size="small"
              sx={{ width: "250px" }}
            />
          </Grid>
        </Grid>
      </StyledDialogContent>
      <StyledDialogAction>
        <StandardButton
          onClick={handleCallBack}
          disabled={!(text === "PROCEED")}
          variant="contained"
          color="primary"
          size="small"
        >
        <ButtonText>Submit</ButtonText> 
        </StandardButton>
      </StyledDialogAction>
    </FormDialog>
  );
};
