export const Upload = {
  "en-US": {
    title: "Upload",
    settings: {
      "x-component-props": {
        accept: "Accept",
        multiple: "Multiple",
        name: "Name",
        maxCount: "Max Count",
        textContent: "Text Content",
      },
    },
  },
};
