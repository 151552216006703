import { createContext, useContext, useState, useMemo, useEffect } from "react";
import {
  useEmployeesData,
  useSectionsData,
  useTagsData,
  useUserListData,
} from "../utilities";
import { useSearchParams } from "react-router-dom";

const GlobalContext = createContext();

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

export const GlobalContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [formDataSchema, setFormDataSchema] = useState({});

  const { employeesData } = useEmployeesData("employees");
  const { sectionsData } = useSectionsData("sections");
  const { tagsData } = useTagsData("tags");
  const { usersData } = useUserListData("users/list-users");

  const usersList = usersData
    ?.map(({ id, employeeId, fullname }) => ({
      id,
      employeeId,
      fullname,
    }))
    ?.sort(
      (a, b) => a.fullname.localeCompare(b.fullname)
    );

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const value = useMemo(
    () => ({
      open,
      handleDrawerOpen,
      handleDrawerClose,
      formDataSchema,
      setFormDataSchema,
      employeesData,
      sectionsData,
      usersList,
      tagsData,
      usersData,
    }),
    [
      open,
      formDataSchema,
      setFormDataSchema,
      employeesData,
      sectionsData,
      usersList,
      tagsData,
      usersData,
    ]
  );

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
