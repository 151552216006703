import {
  Box,
  CssBaseline,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import * as React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MuiAppBar from "@mui/material/AppBar";
import { useNavigate } from "react-router-dom";

const AppBars = styled(MuiAppBar)(({ theme }) => ({
  height: 60,
  background: "#009688",
  color: theme.palette.common.white,
  display: "flex",
  justifyContent: "center",
}));

const FirstColumn = styled(Grid)(() => ({
  justifyContent: "start",
  alignItems: "center",
  display: "flex",
  paddingLeft: "1%",
}));

const SecondColumn = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingRight: "1%",
}));

export const FormHeader = (props) => {
  let navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/");
  };

  const { children } = props;
  return (
    <Box sx={{ display: "flex", backgroundColor: "#e7ebee" }}>
      <CssBaseline />
      <AppBars>
        <Grid container>
          <FirstColumn item xs={6} gap={2}>
            <IconButton color="inherit" onClick={handleNavigate}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" color="#fff">
              Forms Builder Management
            </Typography>
          </FirstColumn>
          <SecondColumn item xs={6} gap={1}>
            <AccountCircleIcon style={{ fontSize: 30 }} />
            <Typography fontSize={"20px"}>{localStorage.getItem("fullName")}</Typography>
          </SecondColumn>
        </Grid>
      </AppBars>
      <Box>{children}</Box>
    </Box>
  );
};
