/* eslint-disable no-throw-literal */
import { addUser, updateUser } from "../api";
import {
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  RESET_INITIAL_STATE,
} from "../constants";

export const userInitialState = {
  loading: true,
  error: false,
  errorType: "",
  errorMessage: "",
  success: false,
  successMessage: "",
  pageUsers: [],
};

export const userActions = {
  submitUser: (data) => async (dispatch) => {
    dispatch({ type: API_PENDING });

    const payload = {
      user: {
        id: 0,
        employeeId: data.employeeId,
        fullname: data.fullname.fullname,
      },
      sections: data.sections,
      roleId: data?.roles,
      status: data.status,
    };
    try {
      await addUser(payload).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to add user.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
      });
      dispatch({
        type: API_SUCCESS,
        payload: `Successfully Added User`,
      });
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },

  editUser: (data) => async (dispatch) => {
    dispatch({ type: API_PENDING });

    const payload = {
      user: {
        id: data.fullname.id,
        employeeId: data.employeeId,
        fullname: data.fullname.fullname,
      },
      sections: data.sections,
      roleId: [2],
      status: data?.status === "ACTIVE" || data?.status === 1 ? 1 : 0,
    };
    try {
      await updateUser(payload).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to add user.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
      });
      dispatch({
        type: API_SUCCESS,
        payload: `Successfully Updated User`,
      });
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
};

export const userReducer = (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case API_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorType: "",
        errorMessage: "",
        success: false,
        successMessage: "",
      };
    case API_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorType: payload.type,
        errorMessage: payload.message,
      };
    case API_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        successMessage: payload,
      };
    case RESET_INITIAL_STATE:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        successMessage: "",
        errorMessage: "",
      };
    default:
      return state;
  }
};
