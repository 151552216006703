import { Field, Formik } from "formik";
import {
  Button,
  Grid,
  TextField,
} from "@mui/material";
import {
  MainDialog,
  ModifiedCustomSelect,
  ModifiedSelectTag,
} from "../../../components";
import { useRef } from "react";
import { FormBuilderValidationSchema } from "../../../constants";
import { FormikTouched } from "../../../utilities";

export const DashboardForm = ({
  innerRef,
  values,
  handleSubmit,
  handleAlertMessage,
  open,
  onClose,
  validateRef,
  setTouchedRef,
}) => {
  const formNameRef = useRef(null);
  const tagRef = useRef(null);
  const descriptionRef = useRef(null);
  const sectionOwnerRef = useRef(null);
  const filerSectionRef = useRef(null);
  const viewerAccessRef = useRef(null);
  const filerRef = useRef(null);
  const remarksRef = useRef(null);

  return (
    <Formik
      innerRef={innerRef}
      initialValues={values}
      onSubmit={handleSubmit}
      validationSchema={FormBuilderValidationSchema}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        isValid,
        touched,
        setTouched,
        handleBlur,
        validateForm,
      }) => {
        if (validateRef) validateRef.current = validateForm;
        if (setTouchedRef) setTouchedRef.current = setTouched;

        return (
          <MainDialog
            title={"Update Form"}
            showDialog={open}
            handleClose={onClose}
            widthType={"sm"}
          >
            <FormikTouched />
            <Grid container rowSpacing={2} paddingX={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  id="formName"
                  name="formName"
                  type="text"
                  label="Form Name"
                  variant="outlined"
                  value={values?.formName ?? ""}
                  required
                  fullWidth
                  size="small"
                  onChange={handleChange}
                  ref={formNameRef}
                  helperText={touched.formName && errors.formName}
                  error={Boolean(errors.formName && touched.formName)}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Field
                  id="tags"
                  name="tags"
                  value={values?.tags}
                  component={ModifiedSelectTag}
                  ref={tagRef}
                  helperText={errors.tags}
                  error={Boolean(errors.tags)}
                  handleBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  id="description"
                  name="description"
                  type="text"
                  multiline
                  fullWidth
                  label="Description"
                  rows={3}
                  size="small"
                  variant="outlined"
                  value={values?.description}
                  onChange={handleChange}
                  ref={descriptionRef}
                  helperText={touched.description && errors.description}
                  error={Boolean(errors.description && touched.description)}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Field
                  id="sectionOwner"
                  name="sectionOwner"
                  value={values?.sectionOwner ?? []}
                  component={ModifiedCustomSelect}
                  label="Section Owner"
                  options={["Section 1", "Section 2", "Section 3"]}
                  ref={sectionOwnerRef}
                  helperText={errors.sectionOwner}
                  error={Boolean(errors.sectionOwner)}
                  handleBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Field
                  id="filerSection"
                  name="filerSection"
                  value={values?.filerSection ?? []}
                  component={ModifiedCustomSelect}
                  label="Filer Section"
                  options={["Section 1", "Section 2", "Section 3"]}
                  ref={filerSectionRef}
                  helperText={errors.filerSection}
                  error={Boolean(errors.filerSection)}
                  handleBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Field
                  id="filer"
                  name="filer"
                  value={values?.filer ?? []}
                  component={ModifiedCustomSelect}
                  label="Filer"
                  options={[
                    "TORNO, KENT MICHAEL CASTILLO",
                    "SANTOS, JAP",
                    "ANGCO, ALLYSA",
                  ]}
                  ref={filerRef}
                  helperText={errors.filer}
                  error={Boolean(errors.filer)}
                  handleBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Field
                  id="viewerAccess"
                  name="viewerAccess"
                  value={values?.viewerAccess ?? []}
                  component={ModifiedCustomSelect}
                  label="Viewer Access"
                  options={[
                    "TORNO, KENT MICHAEL CASTILLO",
                    "SANTOS, JAP",
                    "ANGCO, ALLYSA",
                  ]}
                  ref={viewerAccessRef}
                  helperText={errors.viewerAccess}
                  error={Boolean(errors.viewerAccess)}
                  handleBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  id="remarks"
                  name="remarks"
                  type="text"
                  multiline
                  label="Remarks"
                  fullWidth
                  rows={3}
                  size="small"
                  variant="outlined"
                  value={values?.remarks}
                  onChange={handleChange}
                  ref={remarksRef}
                  helperText={touched.remarks && errors.remarks}
                  error={Boolean(errors.remarks && touched.remarks)}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid container marginTop={"3%"} justifyContent="flex-end">
                <Button
                  type="submit"
                  size="small"
                  style={{
                    fontSize: "0.90rem",
                    padding: "0.8% 3%",
                    color: "#ffffff",
                    textTransform: "capitalize",
                    background: "#1B9A92",
                  }}
                  onClick={(e) => {
                    isValid
                      ? handleSubmit(values)
                      : handleAlertMessage(
                          "Please resolve all fields",
                          "error"
                        );
                  }}
                >
                  UPDATE
                </Button>
              </Grid>
            </Grid>
          </MainDialog>
        );
      }}
    </Formik>
  );
};
