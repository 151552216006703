import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useState } from "react";

export const ModifiedSelectTag = ({ field, helperText, error, handleBlur, data }) => {
  const [options, setOptions] = useState(data);
  const [selectedOptions, setSelectedOptions] = useState(field.value ?? []);
  const [inputValue, setInputValue] = useState("");

  const handleSelection = (event, newValue) => {
    setSelectedOptions(newValue);
    field.onChange({ target: { name: field.name, value: newValue } });
  };

  const handleAddOption = () => {
    const trimmedInputValue = inputValue.trim();
    if (
      trimmedInputValue !== "" &&
      !options.some((option) => option === trimmedInputValue)
    ) {
      setOptions([...options, trimmedInputValue]);
      let updatedValues = [...selectedOptions, trimmedInputValue];
      setSelectedOptions([...selectedOptions, trimmedInputValue]);
      setInputValue("");
      field.onChange({ target: { name: field.name, value: updatedValues } });
    }
  };

  return (
    <Autocomplete
      name={field.name}
      multiple
      id="tags-outlined"
      options={options}
      size="small"
      fullWidth={false}
      value={selectedOptions}
      getOptionLabel={(option) => option}
      getOptionSelected={(option, value) => option === value}
      filterSelectedOptions
      noOptionsText={
        inputValue.trim() !== "" && (
          <Button
            style={{
              textTransform: "none",
              background: "#1B9A92",
              color: "#fff",
            }}
            onClick={handleAddOption}
          >
            Add "{inputValue}"
          </Button>
        )
      }
      renderInput={(params) => (
        <TextField
          label="Tags"
          value={inputValue}
          fullWidth
          helperText={helperText}
          error={error}
          handleBlur={handleBlur}
          onChange={(e) => setInputValue(e.target.value)}
          {...params}
        />
      )}
      onChange={handleSelection}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
    />
  );
};
