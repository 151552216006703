export const Image = {
  "en-us": {
    title: "Image",
    settings: {
      "x-component-props": {
        src: {
          title: "Source",
        },
        // val: {
        //   title: "Value"
        // },
        height: {
          title: "Height",
        },
        width: {
          title: "Width",
        },
        content: "Text Content",
      },
    },
  },
};
