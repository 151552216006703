import { Button, Grid, Typography, styled } from "@mui/material";
import {
  ButtonText,
  FormBuilderCard,
  MainView,
  PageComponent,
  PageSize,
  SearchField,
  StandardButton,
  UserTable,
} from "../../../components";
import {
  RESET_INITIAL_STATE,
  API_PENDING,
  handleUserStatus,
} from "../../../constants";
import { UserForm } from "./user-form/UserForm";
import { useAlert, useForm } from "../../../utilities";
import { UserFilter } from "./user-filter/UserFilter";
import { useUserContext } from "../../../context";
import { useEffect, useState } from "react";
import useAxios from "../../../utilities/useAxios";

const BACKEND_DEV_URL = process.env.REACT_APP_BACKEND_URL;

const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: "1%",
  padding: "1% 3% 0 3%",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    padding: "1% 1% 0 1%",
  },
}));

const FirstColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    width: "100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
  "& > *": {
    flex: "1 1 auto",
    minWidth: "100px",
  },
}));

const SecondColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    alignItems: "stretch",
    gap: theme.spacing(0.5),
  },
}));

const getColorBasedOnName = (name) => {
  switch (name) {
    case "Total Users":
      return "#34516F";
    case "Active Users":
      return "#379f86";
    case "Inactive Users":
      return "#FAAA4C";
    default:
      return "defaultColor";
  }
};

export const UserMaintenance = () => {
  const {
    users,
    usersPageNumber,
    usersPageSize,
    searchKeyword,
    userStatus,
    dateCreated,
    userState,
    dispatchUser,
    optimizedSearchUsers,
    mutateUsers,
    actions: { submitUser, editUser },
    userCardsData,
    mutateUserCardsData,
    sort,
    usersData,
  } = useUserContext();

  const [sorting, setSorting] = useState({ column: "", order: "" });

  const UserMaintenanceCardData = [
    { id: 1, name: "Total Users", value: userCardsData?.totalUsers },
    { id: 2, name: "Active Users", value: userCardsData?.activeUsers },
    { id: 3, name: "Inactive Users", value: userCardsData?.inactiveUsers },
  ];

  const {
    formData,
    showModal,
    handleCloseModal,
    handleDisplayData,
    handleAddData,
  } = useForm();
  const { handleAlertMessage, ...alertRest } = useAlert();

  const {
    showModal: showFilter,
    handleCloseModal: handleCloseFilter,
    handleAddData: handleShowFilter,
  } = useForm();

  const [employeeNames, setEmployeeNames] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchEmployeeNames = async (keyword) => {
    setLoading(true);
    try {
      const response = await useAxios.get(
        `${BACKEND_DEV_URL}/users/section-users`
      );
      const names = response.data?.object?.map((item) => item.fullname) || [];

      setEmployeeNames(response.data.object ?? []);
    } catch (error) {
      console.error("Failed to fetch employee names", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployeeNames("");
  }, []);

  const handleSuccess = () => {
    handleAlertMessage(userState.successMessage, "success");
    mutateUsers();
    mutateUserCardsData();
    handleCloseModal();
    dispatchUser({ type: RESET_INITIAL_STATE });
  };

  const handleError = () => {
    handleAlertMessage(userState.errorMessage, "error");
    handleCloseModal();
    dispatchUser({ type: RESET_INITIAL_STATE });
  };

  useEffect(() => {
    if (userState.successMessage) {
      handleSuccess();
    } else if (userState.errorMessage) {
      handleError();
    }
  }, [userState.success, userState.error]);

  const handleSubmit = (values) => {
    values.sections = values?.sections?.map((item) => item.id);
    console.log("VALUES", values);
    editUser(values);
  };

  const handleSubmitFilter = (status, dateCreated) => {
    optimizedSearchUsers(
      searchKeyword,
      1,
      usersPageSize,
      status,
      dateCreated,
      sort
    );
    handleCloseFilter();
  };

  const handleSearchKeyword = (keyword) => {
    optimizedSearchUsers(
      keyword,
      1,
      usersPageSize,
      userStatus,
      dateCreated,
      sort
    );
  };

  const handleChangePage = (pageNumber) => {
    dispatchUser({ type: API_PENDING });
    optimizedSearchUsers(
      searchKeyword,
      pageNumber,
      usersPageSize,
      userStatus,
      dateCreated,
      sort
    );
  };

  const handleChangePageSize = (pageSize) => {
    optimizedSearchUsers(
      searchKeyword,
      1,
      pageSize,
      userStatus,
      dateCreated,
      sort
    );
  };

  const handleChangeSort = (column) => {
    const newOrder =
      sorting.column === column && sorting.order === "ASC" ? "DESC" : "ASC";

    setSorting({ column: column, order: newOrder });
    const sortValue = `${column},${newOrder}`;

    optimizedSearchUsers(
      searchKeyword,
      usersPageNumber,
      usersPageSize,
      userStatus,
      dateCreated,
      sortValue
    );
  };

  const handleClearFilter = () => {
    optimizedSearchUsers("", usersPageNumber, usersPageSize, "ALL", "", sort);
  };

  return (
    <MainView>
      <PageComponent
        showAlert={alertRest.showAlert}
        handleCloseAlert={alertRest.handleCloseAlert}
        alertSeverity={alertRest.alertSeverity}
        alertMessage={alertRest.alertMessage}
        isLoading={userState.loading}
      >
        <Grid container margin={"0% 3%"}>
          <FormBuilderCard
            data={UserMaintenanceCardData}
            getColorBasedOnName={getColorBasedOnName}
            name="Users"
            loading={userState.loading}
          />
        </Grid>
        <GridContainer container gap={2}>
          <FirstColumn item gap={2}>
            <SearchField search={handleSearchKeyword} keyword={searchKeyword} />
            <PageSize
              pageSizeValue={usersPageSize}
              handleChange={handleChangePageSize}
            />
            <StandardButton onClick={handleShowFilter}>
              <ButtonText>Filter</ButtonText>
            </StandardButton>
            <StandardButton onClick={handleClearFilter}>
              <ButtonText>Clear Filter</ButtonText>
            </StandardButton>
          </FirstColumn>
          <SecondColumn item gap={1}>
            <StandardButton onClick={handleAddData}>
              <ButtonText font>Create User</ButtonText>
            </StandardButton>
          </SecondColumn>
        </GridContainer>
        <Grid container>
          <UserTable
            data={users?.content ?? []}
            handleDisplayData={handleDisplayData}
            handleStatus={handleUserStatus}
            pagination={users}
            usersPageNumber={usersPageNumber}
            handleChangePage={handleChangePage}
            loading={userState.loading}
            handleSort={handleChangeSort}
            sorting={sorting}
          />
        </Grid>
        {formData && (
          <UserForm
            open={showModal}
            onClose={handleCloseModal}
            values={formData}
            handleSubmit={handleSubmit}
            userData={formData}
            handleFetchEmployees={fetchEmployeeNames}
            loading={loading}
            employeeNames={employeeNames}
            handleAlertMessage={handleAlertMessage}
          />
        )}

        {showFilter && (
          <UserFilter
            open={showFilter}
            onClose={handleCloseFilter}
            handleSubmitFilter={handleSubmitFilter}
            userStatus={userStatus}
            dateCreated={dateCreated}
          />
        )}
      </PageComponent>
    </MainView>
  );
};
