import { ButtonText, MainDialog, StandardButton } from "../../../../components";
import { useState } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  styled,
  Typography,
} from "@mui/material";

export const ViewerFilter = ({
  open,
  onClose,
  handleSubmitFilter,
  viewerStatus,
}) => {
  const [status, setStatus] = useState(viewerStatus);
  return (
    <MainDialog
      title={"Filter Viewer"}
      showDialog={open}
      handleClose={onClose}
      widthType={"xs"}
    >
      <Grid container gap={2} justifyContent="center">
        <Grid item xs={12} md={12}>
          <Autocomplete
            value={status}
            disableClearable={true}
            onChange={(e, newValue) => {
              setStatus(newValue);
            }}
            options={["PENDING", "APPROVE", "DENIED", "ALL"]}
            isOptionEqualToValue={(option, value) => {
              if (value === "") {
                return false;
              }
              return option === value;
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Status" />
            )}
          />
        </Grid>

        <Grid container justifyContent={"flex-end"}>
          <StandardButton
            onClick={() => {
              handleSubmitFilter(status);
            }}
          >
            <ButtonText>Submit</ButtonText>
          </StandardButton>
        </Grid>
      </Grid>
    </MainDialog>
  );
};
