import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Button,
  Grid,
  styled,
} from "@mui/material";
import { CustomTextField } from "../input/CustomTextField";
import { FormBuilderValidationSchema } from "../../constants";
import { SelectTag } from "../custom-select/SelectTag";
import { CustomDescriptionField } from "../input/CustomDescriptionField";
import { CustomSelect } from "../custom-select/CustomSelect";
import { useGlobalContext } from "../../context";

const GridError = styled(Grid)(() => ({
  color: "red",
  fontSize: "0.75rem",
  marginTop: "1%",
}));

const FormGrid = styled(Grid)(() => ({
  width: "600px",
  display: "flex",
  padding: "0 8%",
}));

const initialValues = {
  formName: "",
  tags: [],
  description: "",
  sectionOwner: [],
  filerSection: [],
  filer: [],
  viewerAccess: [],
  remarks: "",
};

export const FormBuilder = () => {

  const onSubmit = (values) => {
    console.log("Values", values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={FormBuilderValidationSchema}
    >
      <Form>
        <FormGrid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Field
              name="formName"
              component={CustomTextField}
              label="Form Name"
              variant="outlined"
              fullWidth
            />
            <GridError>
              <ErrorMessage name="formName" component="div" className="error" />
            </GridError>
          </Grid>

          <Grid item xs={12} md={12}>
            <Field
              name="tags"
              component={SelectTag}
              options={["New Option, Option 2"]}
            />
            <GridError>
              <ErrorMessage name="tags" component="div" className="error" />
            </GridError>
          </Grid>

          <Grid item xs={12} md={12}>
            <Field
              name="description"
              component={CustomDescriptionField}
              label="Description"
              variant="outlined"
              fullWidth
            />
            <GridError>
              <ErrorMessage
                name="description"
                component="div"
                className="error"
              />
            </GridError>
          </Grid>

          <Grid item xs={12} md={12}>
            <Field
              name="sectionOwner"
              component={CustomSelect}
              options={["Section 1", "Section 2", "Section 3"]}
              label="Section Owner"
            />
            <GridError>
              <ErrorMessage
                name="sectionOwner"
                component="div"
                className="error"
              />
            </GridError>
          </Grid>
          <Grid item xs={12} md={12}>
            <Field
              name="filerSection"
              component={CustomSelect}
              options={["All", "IT", "HR", "Painting"]}
              label="Filer Section"
            />
            <GridError>
              <ErrorMessage
                name="filerSection"
                component="div"
                className="error"
              />
            </GridError>
          </Grid>
          <Grid item xs={12} md={12}>
            <Field
              name="filer"
              component={CustomSelect}
              options={[
                "ALL",
                "TORNO, KENT MICHAEL CASTILLO",
                "SANTOS, JAP",
                "ANGCO, ALLYSA",
              ]}
              label="Filer"
            />
            <GridError>
              <ErrorMessage name="filer" component="div" className="error" />
            </GridError>
          </Grid>
          <Grid item xs={12} md={12}>
            <Field
              name="viewerAccess"
              component={CustomSelect}
              options={["ALL", "SANTOS, JAP", "ANGCO, ALLYSA"]}
              label="Viewer Access"
            />
            <GridError>
              <ErrorMessage
                name="viewerAccess"
                component="div"
                className="error"
              />
            </GridError>
          </Grid>
          <Grid item xs={12} md={12}>
            <Field
              name="remarks"
              component={CustomDescriptionField}
              label="Remarks"
              variant="outlined"
              fullWidth
            />
            <GridError>
              <ErrorMessage name="remarks" component="div" className="error" />
            </GridError>
          </Grid>
        </FormGrid>

        <Grid
          padding={"0 8%"}
          container
          marginTop={"3%"}
          justifyContent="flex-end"
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            style={{
              fontSize: "0.90rem",
              padding: "0.8% 3%",
              textTransform: "capitalize",
              background: "#1B9A92",
            }}
          >
            Proceed
          </Button>
        </Grid>
      </Form>
    </Formik>
  );
};
