import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useState } from "react";

export const ModifiedCustomSelect = ({
  field,
  label,
  helperText,
  error,
  handleBlur,
  ...otherProps
}) => {
  const [options, setOptions] = useState(otherProps.options ?? []);
  const [selectedOptions, setSelectedOptions] = useState(field.value ?? []);

  const handleSelection = (event, newValue) => {
    setSelectedOptions(newValue);
    field.onChange({ target: { name: field.name, value: newValue } });
  };

  return (
    <Autocomplete
      name={field.name}
      multiple
      id="tags-outlined"
      size="small"
      value={selectedOptions}
      fullWidth={false}
      options={options}
      getOptionLabel={(option) => option?.name}
      getOptionSelected={(option, value) => option?.id === value?.id}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          label={label ?? " "}
          helperText={helperText}
          error={error}
          handleBlur={handleBlur}
          {...params}
        />
      )}
      onChange={handleSelection}
    />
  );
};
