import dayjs from "dayjs";
import { ButtonText, MainDialog, StandardButton } from "../../../../components";
import {
  Autocomplete,
  TextField,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";

export const UserFilter = ({
  open,
  onClose,
  handleSubmitFilter,
  userStatus,
  dateCreated,
}) => {
  const [status, setStatus] = useState(userStatus);
  const [date, setDate] = useState(dateCreated);

  const handleDateChange = (date) => {
    setDate(date ? dayjs(date).format("MM/DD/YYYY") : null);
  };

  return (
    <MainDialog
      title={"Filter User"}
      showDialog={open}
      handleClose={onClose}
      widthType={"xs"}
    >
      <Grid container gap={2} justifyContent="center">
        <Grid item xs={12} md={12}>
          <Autocomplete
            value={status}
            disableClearable={true}
            onChange={(e, newValue) => {
              setStatus(newValue);
            }}
            options={["ALL", "ACTIVE", "INACTIVE"]}
            isOptionEqualToValue={(option, value) => {
              if (value === "") {
                return false;
              }
              return option === value;
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Status" />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date Created"
              value={dateCreated ? dayjs(dateCreated) : null}
              disableHighlightToday
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} size="small" />}
              slotProps={{
                actionBar: {
                  actions: ["clear"],
                },
                textField: { size: "small", error: false, fullWidth: true },
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid container justifyContent="flex-end">
          <StandardButton
            onClick={() => {
              handleSubmitFilter(status, date);
              console.log(dateCreated);
              console.log(date);
            }}
          >
            <ButtonText>Submit</ButtonText>
          </StandardButton>
        </Grid>
      </Grid>
    </MainDialog>
  );
};
