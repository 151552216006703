import { Grid, Typography, styled } from "@mui/material";

const Content = styled(Grid)(({ theme, color }) => ({
  padding: "2% 2%",
  backgroundColor: color,
  borderRadius: 14,
  // boxShadow: `2px 2px 0px ${theme.palette.common.darkGray}`,
}));

const TypographyContainer = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
}));

const CardTypography = styled(Typography)(({ theme, number }) => ({
  fontWeight: number ? "bolder" : "normal",
  fontSize: number ? "1.8rem" : "1.3rem",
  color: "#fff",
}));

export const Card = ({ color, name, value, cardName }) => {
  return (
    <Content item xs={cardName === "Users" ? 3.65 : 2.7} color={color}>
      <Grid container gap={2} justifyContent={"center"}>
        <TypographyContainer item xs={4}>
          <CardTypography number={true}>{value}</CardTypography>
        </TypographyContainer>
        <TypographyContainer item xs={7}>
          <CardTypography>{name}</CardTypography>
        </TypographyContainer>
      </Grid>
    </Content>
  );
};
