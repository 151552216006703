import { Button, Grid, Typography, styled } from "@mui/material";
import { useRef, useState } from "react";
import { ButtonText, StandardButton } from "../styles/StandardButton";

export const FileUpload = ({ field, helperText, error, handleBlur }) => {
  const fileInputRef = useRef(null);

  const [fileUrl, setFileUrl] = useState(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    field.onChange({ target: { name: field.name, value: file } });

    if (file) {
      setFileUrl(URL.createObjectURL(file));
    }
  };

  return (
    <Grid justifyContent="center" rowSpacing={1.5} container>
      <Grid item sx={12} md={12}>
        {fileUrl && (
          <img
            src={fileUrl}
            alt="Upload Image"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} md={12}>
        <StandardButton
          onClick={() => {
            fileInputRef.current.click();
          }}
        >
          <ButtonText>Upload</ButtonText>
        </StandardButton>
        <Typography fontSize={12.5} color="error">
          {!fileUrl && (helperText)}
        </Typography>
      </Grid>

      <input
        type="file"
        accept="image/*"
        onChange={handleFileSelect}
        style={{ display: "none" }}
        ref={fileInputRef}
      />
    </Grid>
  );
};
