export const FormGrid = {
  "en-US": {
    title: "Grid",
    addGridColumn: "Add Grid Column",
    settings: {
      "x-component-props": {
        minWidth: "Min Width",
        minColumns: "Min Columns",
        maxWidth: "Max Width",
        maxColumns: "Max Columns",
        breakpoints: "Breakpoints",
        columnGap: "Column Gap",
        rowGap: "Row Gap",
        colWrap: "Col Wrap",
      },
    },
  },
};

export const FormGridColumn = {
  "en-US": {
    title: "Grid Column",
    settings: {
      "x-component-props": {
        gridSpan: "Grid Span",
      },
    },
  },
};
