import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DashboardIcon from "@mui/icons-material/Dashboard";
import CategoryIcon from "@mui/icons-material/Category";
import { ViewerMaintenance, Dashboard } from "../pages";
import { UserMaintenance } from "../pages/maintenance/user-maintenance/UserMaintenance";
import { UserContextProvider, ViewerContextProvider } from "../context";
import { DashboardContextProvider } from "../context/DashboardContext";
import { UploadMaintenance } from "../pages/maintenance/upload-maintenance/UploadMaintenance";
import { UploadContextProvider } from "../context/UploadContext";
import { ProtectedRoute } from './ProtectedRoute';
import FormEditor from '../pages/form-builder/Editor';
import { FormEditorContextProvider } from '../context';

export const routes = [
  {
    path: "/",
    component: (
      <DashboardContextProvider>
        <Dashboard />
      </DashboardContextProvider>
    ),
    text: "Dashboard",
    icon: <DashboardIcon color="#b9b6a7" />,
    role: "ALL",
  },
  {
    path: "/user-maintenance",
    component: (
      <UserContextProvider>
        <UserMaintenance />
      </UserContextProvider>
    ),
    text: "User Management",
    role: "SECTIONADMIN",
  },
  {
    path: "/viewer-maintenance",
    component: (
      <ViewerContextProvider>
        <ViewerMaintenance />
      </ViewerContextProvider>
    ),
    text: "Viewer Management",
    role: "SECTIONADMIN",
  },
  {
    path: "/upload-maintenance",
    component: (
      <UploadContextProvider>
        <UploadMaintenance />
      </UploadContextProvider>
    ),
    text: "Upload Management",
    role: "SECTIONADMIN",
  },
];

export const AppRoutes = () => (
  <Routes>
    {routes.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        element={
          (route.role === "ALL" || route.role === "SECTIONADMIN")? (
            route.component
          ) : (
            <ProtectedRoute
            component={() => route.component}
            allowedRoles={[route.role]}
            />
          )
        }
      />
    ))}
    <Route
      path="/form-builder"
      element={
      <FormEditorContextProvider>
      <FormEditor />
      </FormEditorContextProvider>
      }
      />
  </Routes>
);

export const collapsibleMenu = {
  text: "Maintenance",
  icon: <CategoryIcon color="#b9b6a7" />,
  role: "SUPERADMIN",
};
