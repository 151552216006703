import {
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Tooltip,
} from "@mui/material";
import { ViewerMaintenanceColumnSchema } from "../../constants";
import EditIcon from "@mui/icons-material/Edit";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import { CustomPagination } from "../custom-pagination/CustomPagination";
import moment from "moment";
import { Typography } from "antd";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const TableCellStyled = styled(TableCell)(() => ({
  textAlign: "left",
  fontSize: "0.90rem",
  padding: "20px 20px",
  width: "auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  borderBottom: "1px solid #e0e0e0",
  "&:first-child": {
    padding: "0px 30px",
  },
  "&:last-child": {
    width: "100px",
  },
}));

const TableColumnCellStyled = styled(TableCell)(() => ({
  "&:first-child": {
    textAlign: "left",
    padding: "0px 30px",
  },
  fontSize: ".95rem",
  fontWeight: "bold",
  textTransform: "capitalize",
  padding: "20px 20px",
  width: "auto",
  borderBottom: "1px solid #e0e0e0",
  backgroundColor: "#f7f7f7",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "&:last-child": {
    width: "150px",
  },
}));

const FormNameCellStyled = styled(TableCellStyled)(() => ({
  whiteSpace: "normal",
  overflow: "visible",
  textOverflow: "unset",
}));

export const ViewerTable = ({
  data,
  handleStatus,
  handleSort,
  pagination,
  handleChangePage,
  loading,
  viewersPageNumber,
  sorting,
  handleDisplayData,
  handleDisplayDelete,
}) => {
  const handleNextPage = () => {
    handleChangePage(viewersPageNumber + 1);
  };

  const handlePreviousPage = () => {
    handleChangePage(viewersPageNumber - 1);
  };

  const totalRange = () => {
    const start = data?.length > 0 ?  pagination?.pageable?.pageNumber * pagination?.size + 1 : 0;
    const end =data?.length > 0 ?  Math.min(
      pagination?.pageable?.pageNumber * pagination?.size +
        pagination?.numberOfElements,
      pagination?.totalElements
    ) : 0;
    const total = data?.length > 0 ?  pagination?.totalElements : 0;
    return { start, end, total };
  };

  const { start, end, total } = totalRange();
  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {ViewerMaintenanceColumnSchema.map((column) => (
                <TableColumnCellStyled key={column.id}>
                  {column.label}
                  {column.hasSorting && (
                    <IconButton
                      onClick={() => {
                        handleSort(column.id);
                      }}
                      sx={{ width: 20, height: 20 }}
                    >
                      {sorting.column === column.id &&
                      sorting.order === "ASC" ? (
                        <ArrowUpwardIcon
                          sx={{ color: "#000", fontSize: "18px" }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{ color: "#000", fontSize: "18px" }}
                        />
                      )}
                    </IconButton>
                  )}
                </TableColumnCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <TableRow key={index}>
                <FormNameCellStyled>{row.formName}</FormNameCellStyled>
                <TableCellStyled>{row.requestorName}</TableCellStyled>
                <TableCellStyled>
                  {row.sectionOwner ?? "No Section"}
                </TableCellStyled>
                <TableCellStyled>
                  <Chip
                    color={handleStatus(row.status)}
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      backgroundColor:
                        row.status === "APPROVED"
                          ? "#379f86"
                          : row.status === "PENDING"
                          ? "#FAAA4C"
                          : row.status === "DENIED"
                          ? "#D14B56"
                          : "",
                    }}
                    label={row.status}
                  />
                </TableCellStyled>
                <TableCellStyled>
                  {row.status === "PENDING" ? (
                    <Grid
                      display={"flex"}
                      gap={"5%"}
                      justifyContent={"flex-start"}
                    >
                      <IconButton
                        onClick={() =>
                          handleDisplayData({ id: row.id, type: "Approve" })
                        }
                      >
                        <CheckCircleIcon color="success" />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleDisplayData({ id: row.id, type: "Deny" })
                        }
                      >
                        <DoNotDisturbAltIcon color="error" />
                      </IconButton>
                      <Tooltip title="Remove Viewer Access" placement="top">
                        <IconButton onClick={() => handleDisplayDelete(row)}>
                          <RemoveCircleIcon style={{ color: "#444444" }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ) : null}
                  {row.status === "DENIED" ? (
                    <Grid display={"flex"} justifyContent={"flex-start"}>
                      <IconButton
                        onClick={() =>
                          handleDisplayData({ id: row.id, type: "Approve" })
                        }
                      >
                        <CheckCircleIcon color="success" />
                      </IconButton>
                      <Tooltip title="Remove Viewer Access" placement="top">
                        <IconButton onClick={() => handleDisplayDelete(row)}>
                          <RemoveCircleIcon style={{ color: "#444444" }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ) : null}
                  {row.status === "APPROVED" ? (
                    <Grid display={"flex"} justifyContent={"flex-start"}>
                      <Tooltip title="Remove Viewer Access" placement="top">
                        <IconButton onClick={() => handleDisplayDelete(row)}>
                          <RemoveCircleIcon style={{ color: "#444444" }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ) : null}
                </TableCellStyled>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {!loading && data.length === 0 && (
          <Box marginTop={2}>
            <Typography textAlign={"center"}>No Data Available</Typography>
          </Box>
        )}
      </TableContainer>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="0px 20px"
        backgroundColor="#ffffff"
        width="100%"
        borderRadius="2px"
      >
        <Grid container alignItems="center">
          <Grid item xs={4} />
          <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
            <CustomPagination
              pageNumber={viewersPageNumber ?? 1}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              disableFirst={pagination?.length === 0 || pagination?.first}
              disableNext={
                pagination?.length === 0 || pagination?.last || loading
              }
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "5px",
            }}
          >
            <Typography style={{ color: "#8E8E8E" }}>
              Showing {start} to {end} of {total}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
