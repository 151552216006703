import { Autocomplete, TextField, styled } from "@mui/material";
import { useState } from "react";

const TestData = [
  { label: "Painting", value: "Painting" },
  { label: "IT Section", value: "IT" },
  { label: "HR Section", value: "HR" },
];

const AutoCompleteFilter = styled(Autocomplete)(() => ({
  backgroundColor: "#fff",
  width: "100%",
  maxWidth: "290px",
  "@media (max-width: 600px)": {
    maxWidth: "100%",
  },
}));

export const FilterSection = () => {
  const [value, setValue] = useState("Painting");

  const handleFilterChange = (event, value) => {
    setValue(value?.value);
  };

  return (
    <AutoCompleteFilter
      disablePortal
      id="combo-box-demo"
      options={TestData.map((item) => item.value)}
      value={value}
      size="medium"
      onChange={handleFilterChange}
      renderInput={(params) => <TextField {...params} label="Sections" />}
    />
  );
};
