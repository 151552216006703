import { useEffect, useState } from "react";
import { useFormikContext, setNestedObjectValues } from "formik";

export const FormikTouched = () => {
	const { errors, setTouched, isSubmitting, isValidating } = useFormikContext();
	const [alreadySet, setSet] = useState(false);
	useEffect(() => {
		if (isSubmitting && !isValidating && !alreadySet) {
			setTouched(setNestedObjectValues(errors, true));
			setSet(true);
		}
	}, [errors, isSubmitting, isValidating, setTouched, alreadySet]);

	return null;
};
