export const RadioGroup = {
  "en-US": {
    title: "Radio",
    settings: {
      "x-component-props": {
        buttonStyle: { title: "Button style", dataSource: ["Hollow", "Solid"] },
        optionType: { title: "Option type", dataSource: ["Default", "Button"] },
      },
    },
  },
};
