import { useDesigner } from "@designable/react";
import {
  transformToSchema,
  transformToTreeNode,
} from "@designable/formily-transformer";
import { observer } from "@formily/react";
import { Space } from "antd";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import React, { useEffect } from "react";
import { useModal } from "../../../utilities";
import { ConfirmationDialog, ModifiedFormBuilder } from "../../../components";
import { useFormEditorContext, useGlobalContext } from "../../../context";
import { RESET_INITIAL_STATE } from "../../../constants";

const formButtonStyle = {
  height: 38,
  width: "100%",
  minWidth: "30px",
  borderRadius: 4,
  background: "#379F86",
  color: "#fff",
};

export const ActionsWidget = observer(({ handleAlertMessage }) => {
  const { formDataSchema, setFormDataSchema } = useGlobalContext();

  const designer = useDesigner();

  const {
    actions: {
      submitForm,
      editForm,
      saveSchema,
      publishSchema,
      saveAndPublish,
    },
    formEditorState,
    dispatchFormEditor,
    usersList,
  } = useFormEditorContext();

  const {
    isModalOpen: isFormOpen,
    showModal: showFormModal,
    closeModal: closeFormModal,
  } = useModal(true);

  const {
    isModalOpen: isPublishOpen,
    showModal: showPublishModal,
    closeModal: closePublishModal,
  } = useModal(false);

  const handleSuccess = () => {
    handleAlertMessage(formEditorState.successMessage, "success");
    closeFormModal();

    if (formEditorState.resultType === "ADD_FORM") {
      let object = formEditorState.result;
      object["formId"] = object.id;
      object["id"] = object.formDesignId;

      setFormDataSchema(object);
    } else if (formEditorState.resultType === "UPDATE_FORM") {
      let formId = formDataSchema.formId;
      let formDesignId = formDataSchema.id;
      let design = formDataSchema.schema;
      let object = formEditorState.result;

      object["id"] = formDesignId;
      object["formId"] = formId;
      object["design"] = design;

      setFormDataSchema(object);
    } else if (formEditorState.resultType === "UPDATE_FORM_SCHEMA") {
      let object = formDataSchema;
      object["design"] = formEditorState.result?.schema;
      object["id"] = formEditorState?.result.id;

      setFormDataSchema(object);
    } else if (formEditorState.resultType === "PUBLISH_FORM_SCHEMA") {
      let updateFormDataSchema = formEditorState.result?.schema;
      updateFormDataSchema.formStatus = 2;
      updateFormDataSchema["id"] = formEditorState.result?.id;
      setFormDataSchema(updateFormDataSchema);
      closePublishModal();
    }

    dispatchFormEditor({ type: RESET_INITIAL_STATE });
  };

  const handleError = () => {
    handleAlertMessage(formEditorState.errorMessage, "  error");
    closeFormModal();
    dispatchFormEditor({ type: RESET_INITIAL_STATE });
  };

  useEffect(() => {
    if (formEditorState.successMessage) {
      handleSuccess();
    } else if (formEditorState.errorMessage) {
      handleError();
    }
  }, [formEditorState.success, formEditorState.error]);

  const handleSubmitForm = (values) => {
    if (!values?.formId) {
      submitForm(values);
    } else {
      editForm(values);
    }
  };

  const handleSubmitSchema = () => {
    const schema = transformToSchema(designer.getCurrentTree());

    const data = {
      formId: formDataSchema.formId,
      formDesignId: formDataSchema.id,
    };
    saveSchema(data, schema);
  };

  const handlePublishModal = () => {
    publishSchema({
      id: formDataSchema.id,
      formStatus: 2,
      formId: formDataSchema.formId,
    });
  };

  const handleSaveAndPublish = () => {
    const schema = transformToSchema(designer.getCurrentTree());

    saveAndPublish(
      {
        id: formDataSchema.id,
        formStatus: 2,
        formId: formDataSchema.formId,
        schema: schema,
      },
      publishSchema
    );
  };

  const loadSchema = (values) => {
    try {
      designer.setCurrentTree(transformToTreeNode(values));
    } catch (e) {
      handleAlertMessage("Please choose a valid form", "error");
      console.error(e);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (formDataSchema.design != null) {
      if (Object.keys(formDataSchema?.design)?.length > 0) {
        loadSchema(formDataSchema.design);
      }
    }
  }, [formDataSchema]);

  return (
    <>
      <Space
        style={{
          justifyContent: "space-between",
          marginRight: 15,
        }}
      >
        <CustomButton
          label="Form Details"
          style={formButtonStyle}
          onClickHandler={showFormModal}
          formId={true}
        />
        <CustomButton
          label="Save"
          style={formButtonStyle}
          onClickHandler={handleSubmitSchema}
          formId={formDataSchema.formId}
        />

        <CustomButton
          label="Publish"
          style={formButtonStyle}
          onClickHandler={showPublishModal}
          formId={formDataSchema.formId}
        />

        {isFormOpen && (
          <ModifiedFormBuilder
            open={isFormOpen}
            onClose={closeFormModal}
            values={formDataSchema}
            handleSubmit={handleSubmitForm}
            handleAlertMessage={handleAlertMessage}
            usersList={usersList}
          />
        )}

        {isPublishOpen && (
          <ConfirmationDialog
            open={isPublishOpen}
            title={"Publish Schema"}
            handleCallBack={handleSaveAndPublish}
            onClose={closePublishModal}
          />
        )}
      </Space>
    </>
  );
});
