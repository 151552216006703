import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
  useReducer,
} from "react";
import {
  useSectionUserListData,
  useUserCardsData,
  useUserListData,
  useUsersData,
} from "../utilities";
import {
  userActions,
  userReducer,
  userInitialState,
} from "../reducer/UserReducer";
import { RESET_INITIAL_STATE, API_PENDING } from "../constants";

const UserContext = createContext();

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserContextProvider = ({ children }) => {
  const {
    users,
    usersPageNumber,
    usersPageSize,
    userStatus,
    searchKeyword,
    dateCreated,
    sort,
    optimizedSearchUsers,
    mutateUsers,
  } = useUsersData("users/search?");

  const { usersData } = useSectionUserListData("users/section-users");

  const { userCardsData, mutateUserCardsData } =
    useUserCardsData("users/user-cards");

  const [userState, dispatchUser] = useReducer(userReducer, {
    ...userInitialState,
    pageUsers: users,
  });

  useEffect(() => {
    mutateUsers();
    dispatchUser({ type: RESET_INITIAL_STATE });
  }, [
    usersPageNumber,
    usersPageSize,
    userStatus,
    searchKeyword,
    dateCreated,
    sort,
  ]);

  const [actions, setActions] = useState({});
  const stateRef = useRef();

  useEffect(() => {
    dispatchUser({ type: API_PENDING });
    mutateUserCardsData().finally(() => {
      dispatchUser({ type: RESET_INITIAL_STATE });
    });
  }, []);

  useEffect(() => {
    Object.keys(userActions).forEach((key) => {
      setActions((curr) => ({
        ...curr,
        [key]: (...args) => {
          userActions[key](...args)(dispatchUser, stateRef.current);
        },
      }));
    });
  }, []);

  useEffect(() => {
    stateRef.current = userState;
  }, [userState]);

  const value = useMemo(
    () => ({
      userStatus,
      dateCreated,
      users,
      usersPageNumber,
      usersPageSize,
      searchKeyword,
      userState,
      dispatchUser,
      optimizedSearchUsers,
      mutateUsers,
      actions,
      userCardsData,
      mutateUserCardsData,
      sort,
      usersData,
    }),
    [
      userStatus,
      dateCreated,
      users,
      usersPageNumber,
      usersPageSize,
      searchKeyword,
      userState,
      dispatchUser,
      optimizedSearchUsers,
      mutateUsers,
      actions,
      userCardsData,
      mutateUserCardsData,
      sort,
      usersData,
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
