import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { styled } from "@mui/material";

const SearchTextField = styled(TextField)(() => ({
  backgroundColor: "#fff",
  width: "100%",
  maxWidth: "290px",
  "@media (max-width: 600px)": {
    maxWidth: "100%",
  },
}));

export const SearchField = ({ search, keyword }) => {
  const [value, setValue] = useState(keyword);

  const handleSearch = (event) => {
    setValue(event.target.value);
    search(event.target.value);
  };

  useEffect(() => {
    setValue(keyword);
  }, [keyword]);

  return (
    <SearchTextField
      value={value}
      placeholder="Search..."
      variant="outlined"
      size="small"
      onChange={handleSearch}
      InputProps={{
        startAdornment: (
          <SearchIcon style={{ color: "gray", marginRight: 10 }} />
        ),
      }}
    />
  );
};
