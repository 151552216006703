import React from "react";
import { Checkbox as FormilyCheckbox } from "@formily/antd";
import { createBehavior, createResource } from "@designable/core";
import { createFieldSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
import { connect, mapProps } from "@formily/react";
import ReactMarkdown from "react-markdown";

const CustomCheckbox = (props) => {
  const { label, ...rest } = props;
  return (
    <FormilyCheckbox {...rest}>
      <ReactMarkdown children={label} />
    </FormilyCheckbox>
  );
};

export const Checkbox = connect(
  CustomCheckbox,
  mapProps({
    value: "checked",
  })
);

Checkbox.Group = FormilyCheckbox.Group;

Checkbox.Behavior = createBehavior(
  {
    name: "Checkbox",
    extends: ["Field"],
    selector: (node) => node.props["x-component"] === "Checkbox.Group",
    designerProps: {
      propsSchema: createFieldSchema(AllSchemas.Checkbox.Group),
    },
    designerLocales: AllLocales.CheckboxGroup,
  },
  {
    name: "Checkbox",
    extends: ["Field"],
    selector: (node) => node.props["x-component"] === "Checkbox",
    designerProps: {
      propsSchema: createFieldSchema(AllSchemas.Checkbox),
    },
    designerLocales: AllLocales.Checkbox,
  }
);

Checkbox.Resource = createResource(
  {
    icon: "CheckboxGroupSource",
    elements: [
      {
        componentName: "Field",
        props: {
          type: "Array<string | number>",
          title: "Checkbox Group",
          "x-decorator": "FormItem",
          "x-component": "Checkbox.Group",
          enum: [
            { label: "option1", value: 1 },
            { label: "option2", value: 2 },
          ],
        },
      },
    ],
  },
  {
    icon: "CheckboxGroupSource",
    elements: [
      {
        componentName: "Field",
        props: {
          type: "string",
          "x-component": "Checkbox",
          "x-decorator": "FormItem",
          "x-component-props": {
            label: "Checkbox label",
          },
        },
      },
    ],
  }
);
