import { Grid, styled, Button, Typography } from "@mui/material";
import {
  ButtonText,
  MainView,
  PageComponent,
  PageSize,
  SearchField,
  StandardButton,
} from "../../../components";
import { RESET_INITIAL_STATE, API_PENDING, handleViewerStatus } from "../../../constants";
import { useViewerContext } from "../../../context";
import { useAlert, useForm } from "../../../utilities";
import { useEffect, useState } from "react";
import { ViewerTable } from "../../../components/table/ViewerTable";
import { ViewerForm } from "./viewer-form/ViewerForm";
import { ViewerFilter } from "../..";
import { DeleteViewer } from "./delete-viewer-form/DeleteViewer";

const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: "1%",
  padding: "1% 3% 0 3%",
  justifyContent: "space-between",
  [theme.breakpoints.down('sm')]: {
    padding: "1% 1% 0 1%",
  },
}));

const FirstColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    width: "100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
  "& > *": {
    flex: "1 1 auto", 
    minWidth: "100px", 
  },
}));

const SecondColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    alignItems: "stretch",
    gap: theme.spacing(0.5),
  },
}));

export const ViewerMaintenance = () => {
  const {
    viewerState,
    dispatchViewer,
    viewers,
    viewersPageNumber,
    viewersPageSize,
    searchKeyword,
    mutateViewers,
    optimizedSearchViewers,
    sort,
    viewerStatus,
    actions: { editViewer, removeViewer },
  } = useViewerContext();

  const [sorting, setSorting] = useState({ column: "", order: "" });

  const { formData, showModal, handleCloseModal, handleDisplayData } =
    useForm();

  const {
    showModal: showFilter,
    handleCloseModal: handleCloseFilter,
    handleAddData: handleShowFilter,
  } = useForm();

  const {
    formData: deleteViewerData,
    showModal: showDeleteViewer,
    handleCloseModal: handleCloseDeleteViewer,
    handleDisplayData: handleShowDeleteViewer,
  } = useForm();

  const { handleAlertMessage, ...alertRest } = useAlert();

  useEffect(() => {
    if (viewers) {
      dispatchViewer({ type: RESET_INITIAL_STATE });
    }
  }, [viewers]);

  const handleSearchKeyword = (keyword) => {
    optimizedSearchViewers(keyword, 1, viewersPageSize, viewerStatus);
  };

  const handleChangePage = (pageNumber) => {
    dispatchViewer({type: API_PENDING})
    optimizedSearchViewers(
      searchKeyword,
      pageNumber,
      viewersPageSize,
      viewerStatus
    );
  };

  const handleChangePageSize = (pageSize) => {
    optimizedSearchViewers(searchKeyword, 1, pageSize, viewerStatus);
  };

  const handleChangeSort = (column) => {
    const newOrder =
      sorting.column === column && sorting.order === "ASC" ? "DESC" : "ASC";

    setSorting({ column: column, order: newOrder });
    const sortValue = `${column},${newOrder}`;

    optimizedSearchViewers(
      searchKeyword,
      viewersPageNumber,
      viewersPageSize,
      viewerStatus
    );
  };

  const handleSubmitFilter = (status) => {
    optimizedSearchViewers(searchKeyword, 1, viewersPageSize, status);
    handleCloseFilter();
  };

  const handleClearFilter = () => {
    optimizedSearchViewers("", viewersPageNumber, viewersPageSize, "ALL");
  };

  const handleSubmit = (viewerId, status) => {
    editViewer(viewerId, status);
  };

  const handleSubmitDelete = (data) => {
    console.log("Data ", data);
    removeViewer(data)
  }

  const handleSuccess = () => {
    handleAlertMessage(viewerState.successMessage, "success");
    mutateViewers();
    handleCloseModal();
    handleCloseDeleteViewer();
    dispatchViewer({ type: RESET_INITIAL_STATE });
  };

  const handleError = () => {
    handleAlertMessage(viewerState.errorMessage, "error");
    handleCloseModal();
    handleCloseDeleteViewer();
    dispatchViewer({ type: RESET_INITIAL_STATE });
  };

  useEffect(() => {
    if (viewerState.successMessage) {
      handleSuccess();
    } else if (viewerState.errorMessage) {
      handleError();
    }
  }, [viewerState.success, viewerState.error]);

  return (
    <MainView>
      <PageComponent
        showAlert={alertRest.showAlert}
        handleCloseAlert={alertRest.handleCloseAlert}
        alertSeverity={alertRest.alertSeverity}
        alertMessage={alertRest.alertMessage}
        isLoading={viewerState.loading}
      >
        <GridContainer container gap={2}>
          <FirstColumn item gap={2}>
            <SearchField search={handleSearchKeyword} keyword={searchKeyword} />
            <PageSize
              pageSizeValue={viewersPageSize}
              handleChange={handleChangePageSize}
            />
             <StandardButton onClick={handleShowFilter}>
              <ButtonText>Filter</ButtonText>
            </StandardButton>
            <StandardButton onClick={handleClearFilter}>
              <ButtonText>Clear Filter</ButtonText>
            </StandardButton>
          </FirstColumn>
          <SecondColumn item gap={1}>
          </SecondColumn>
        </GridContainer>
        <GridContainer container>
          <ViewerTable
            data={viewers?.content ?? []}
            handleStatus={handleViewerStatus}
            pagination={viewers}
            viewersPageNumber={viewersPageNumber}
            handleChangePage={handleChangePage}
            loading={viewerState.loading}
            handleSort={handleChangeSort}
            sorting={sorting}
            handleDisplayData={handleDisplayData}
            handleDisplayDelete={handleShowDeleteViewer}
          />
        </GridContainer>
        {formData && (
          <ViewerForm
            values={formData}
            open={showModal}
            onClose={handleCloseModal}
            handleSubmit={handleSubmit}
          />
        )}

        {deleteViewerData && (
          <DeleteViewer
            values={deleteViewerData}
            open={showDeleteViewer}
            onClose={handleCloseDeleteViewer}
            handleSubmit={handleSubmitDelete}
          />
        )}
        {showFilter && (
          <ViewerFilter
            open={showFilter}
            onClose={handleCloseFilter}
            handleSubmitFilter={handleSubmitFilter}
            viewerStatus={viewerStatus}
          />
        )}
      </PageComponent>
    </MainView>
  );
};
