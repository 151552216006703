import ImageSetter from "../setters/ImageSetter/component";

export const Image = {
  type: "object",
  properties: {
    src: {
      type: "string",
      name: "value",
      "x-decorator": "FormItem",
      "x-component": ImageSetter,
      "x-component-props": {},
    },
    width: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "Input",
      "x-component-props": {
        defaultValue: "100px",
      },
    },
    height: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "Input",
      "x-component-props": {
        defaultValue: "100px",
      },
    },
  },
};
