import { Button } from "antd";
import { TextWidget } from "@designable/react";

export const CustomButton = ({label, style, onClickHandler, formId}) => {
    return (
    <Button
      onClick={onClickHandler}
      style={{
        ...style,
        background: formId ? style.background : "gray",
        cursor: formId ? "pointer" : "not-allowed"
        }}
      disabled= {!formId}>
      <TextWidget>{label}</TextWidget>
    </Button>
    )
  };