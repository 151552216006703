import React, { useEffect } from "react";
import { usePrefix } from "@designable/react";
import { observer } from "@formily/react";
import { observable } from "@formily/reactive";
import { Button, Image, Modal, Select, Space } from "antd";
import { useMemo, useState } from "react";
import { useGetSectionImages } from "../../../../utilities/useFetchData";
import { useGlobalContext } from "../../../../context";

const ImageSetter = observer((props) => {
  const { formDataSchema } = useGlobalContext();
  const { value = "", onChange } = props;
  const { sectionImageData, mutateSectionImageData } = useGetSectionImages(
    "files/get-file-sections"
  );

  const [modalVisible, setModalVisible] = useState(false);

  const rawValue = useMemo(
    () =>
      observable({
        raw: value,
      }),
    [value, modalVisible]
  );

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  const handleChange = (value) => {
    rawValue.raw = value;
  };

  useEffect(() => {
    mutateSectionImageData();
  }, [formDataSchema]);

  return (
    <React.Fragment>
      <Button block onClick={openModal}>
        Change Image
      </Button>

      <Modal
        title="Update Image"
        width="25%"
        bodyStyle={{ padding: 10, justifyContent: "center", display: "flex" }}
        transitionName=""
        maskTransitionName=""
        open={modalVisible}
        onCancel={closeModal}
        onOk={() => {
          onChange(rawValue.raw);
          closeModal();
        }}
      >
        <Space
          direction="vertical"
          style={{ justifyContent: "center", textAlign: "center" }}
        >
          <Select
            onChange={handleChange}
            value={rawValue.raw}
            defaultValue={rawValue.raw}
            aria-label="Select Image"
            options={sectionImageData}
            style={{ width: "250px" }}
          />
          <Image
            src={rawValue.raw}
            preview={false}
            height="200px"
            width="250px"
          />
        </Space>
      </Modal>
    </React.Fragment>
  );
});

export default ImageSetter;
