import dayjs from "dayjs";
import { ButtonText, MainDialog, StandardButton } from "../../../../components";
import {
  Autocomplete,
  TextField,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";

export const UploadFilter = ({
  open,
  onClose,
  handleSubmitFilter,
  type
}) => {
    const [fileType, setFileType] = useState(type);

  return (
    <MainDialog
      title={"Filter Upload"}
      showDialog={open}
      handleClose={onClose}
      widthType={"xs"}
    >
      <Grid container gap={2} padding={2} justifyContent="center">
        <Grid item xs={12} md={12}>
          <Autocomplete
            value={fileType}
            disableClearable={true}
            onChange={(e, newValue) => {
                setFileType(newValue);
            }}
            options={["JPG", "XLSX", "PDF", "DOCS", "PNG", "CSV", "ALL"]}
            isOptionEqualToValue={(option, value) => {
              if (value === "") {
                return false;
              }
              return option === value;
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" label="File Type" />
            )}
          />
        </Grid>

        <Grid container margin={"3% 0% 0% 3%"} justifyContent="flex-end">
          <StandardButton
            onClick={() => {
              handleSubmitFilter(fileType);
            }}
          >
            <ButtonText>Submit</ButtonText>
          </StandardButton>   
        </Grid>
      </Grid>
    </MainDialog>
  );
};