export const Component = {
  "en-US": {
    settings: {
      style: {
        width: "Width",
        height: "Height",
        display: "Display",
        background: "Background",
        boxShadow: "Box Shadow",
        font: "Font",
        margin: "Margin",
        padding: "Padding",
        borderRadius: "Radius",
        border: "Border",
        opacity: "Opacity",
      },
    },
  },
};
