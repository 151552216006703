export const TextArea = {
  "en-US": {
    title: "TextArea",
    settings: {
      "x-component-props": {
        maxLength: "Max Length",
        autoSize: "Auto Size",
        showCount: "Show Count",
      },
    },
  },
};
