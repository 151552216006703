import { Select as FormilySelect } from "@formily/antd";
import { createBehavior, createResource } from "@designable/core";
import { createFieldSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";

export const Select = FormilySelect;

Select.Behavior = createBehavior(
  {
    name: "Select",
    extends: ["Field"],
    selector: (node) =>
      node.props["x-component"] === "Select" &&
      node.props["x-data-select-type"] === "select",
    designerProps: {
      propsSchema: createFieldSchema(AllSchemas.Select),
    },
    designerLocales: AllLocales.Select,
  },
  {
    name: "SelectEmployees",
    extends: ["Field"],
    selector: (node) =>
      node.props["x-component"] === "Select" &&
      node.props["x-data-select-type"] === "employees",
    designerProps: {
      propsSchema: createFieldSchema(AllSchemas.Select),
    },
    designerLocales: AllLocales.SelectEmployees,
  },
  {
    name: "SelectSections",
    extends: ["Field"],
    selector: (node) =>
      node.props["x-component"] === "Select" &&
      node.props["data-select-type"] === "sections",
    designerProps: {
      propsSchema: createFieldSchema(AllSchemas.Select),
    },
    designerLocales: AllLocales.SelectSections,
  }
);

Select.Resource = createResource(
  {
    icon: "SelectSource",
    elements: [
      {
        componentName: "Field",
        props: {
          title: "Select",
          "x-decorator": "FormItem",
          "x-component": "Select",
          "x-data-select-type": "select",
        },
      },
    ],
  },
  {
    icon: "SelectSource",
    elements: [
      {
        componentName: "Field",
        props: {
          title: "Employees",
          "x-decorator": "FormItem",
          "x-component": "Select",
          "x-reactions": "{{fetchEmployees}}",
          "x-data-select-type": "employees",
        },
      },
    ],
  },
  {
    icon: "SelectSource",
    elements: [
      {
        componentName: "Field",
        props: {
          title: "Sections",
          "x-decorator": "FormItem",
          "x-component": "Select",
          "x-reactions": "{{fetchSections}}",
          "data-select-type": "sections",
        },
      },
    ],
  }
);
