import {
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Tooltip,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomPagination } from "../custom-pagination/CustomPagination";
import { UploadMaintenanceColumnSchema } from "../../constants";
import moment from "moment";
import { Typography } from "antd";

const TableRowCellStyled = styled(TableCell)(({ theme }) => ({
  "&:first-child": {
    textTransform: "uppercase",
    padding: "0px 30px",
  },
  "&:nth-child(4)": {
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textAlign: "left",
  fontSize: "0.90rem",
  padding: "20px 20px",
  minWidth: "100px",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  overflow: "hidden",
  textOverflow: "ellipsis",
  borderBottom: "1px solid #e0e0e0",
  "&:last-child": {
    width: "150px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "8px 12px",
    fontSize: "0.80rem",
  },
}));

const TableColumnCellStyled = styled(TableCell)(() => ({
  "&:first-child": {
    padding: "0 30px",
  },
  textAlign: "left",
  fontSize: ".95rem",
  fontWeight: "bold",
  textTransform: "capitalize",
  padding: "20px 20px",
  width: "auto",
  borderBottom: "1px solid #e0e0e0",
  backgroundColor: "#f7f7f7",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "&:last-child": {
    width: "150px",
  },
}));

export const UploadTable = ({
  data,
  handleSort,
  handleConfirmation,
  loading,
  pagination,
  handleChangePage,
  uploadsPageNumber,
  sorting,
}) => {
  const handleNextPage = () => {
    handleChangePage(uploadsPageNumber + 1);
  };

  const handlePreviousPage = () => {
    handleChangePage(uploadsPageNumber - 1);
  };

  const totalRange = () => {
    const start = data?.length > 0 ?  pagination?.pageable?.pageNumber * pagination?.size + 1 : 0;
    const end =data?.length > 0 ?  Math.min(
      pagination?.pageable?.pageNumber * pagination?.size +
        pagination?.numberOfElements,
      pagination?.totalElements
    ) : 0;
    const total = data?.length > 0 ?  pagination?.totalElements : 0;
    return { start, end, total };
  };

  const { start, end, total } = totalRange();

  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {UploadMaintenanceColumnSchema.map((column) => (
                <TableColumnCellStyled key={column.id}>
                  {column.label}
                </TableColumnCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <TableRow key={index}>
                <TableRowCellStyled>{row.fileType}</TableRowCellStyled>
                <TableRowCellStyled>{row.originalName}</TableRowCellStyled>
                <TableRowCellStyled>
                  <a href={row.url} target="_blank" rel="noopener noreferrer">
                    {row.url}
                  </a>
                </TableRowCellStyled>
                <TableRowCellStyled>{row.sectionName}</TableRowCellStyled>
                <TableRowCellStyled>
                  {moment(row.dateCreated).format("YYYY-MM-DD")}
                </TableRowCellStyled>
                <TableRowCellStyled>
                  <Grid container gap={0.5} justifyContent="left">
                    <Tooltip title="Delete Upload" placement="top">
                      <IconButton
                        onClick={() => {
                          handleConfirmation(row);
                        }}
                      >
                        <DeleteIcon style={{ color: "#444444" }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </TableRowCellStyled>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {data.length === 0 && (
          <Box marginTop={2}>
            <Typography textAlign={"center"}>No Data Available</Typography>
          </Box>
        )}
      </TableContainer>

      <Box
        display="flex"
        justifyContent="center" 
        alignItems="center"
        padding="0px 20px"
        backgroundColor="#ffffff"
        width="100%"
        borderRadius="2px"
      >
        <Grid container alignItems="center">
          <Grid item xs={4} />
          <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
            <CustomPagination
              pageNumber={uploadsPageNumber ?? 1}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              disableFirst={pagination?.length === 0 || pagination?.first}
              disableNext={
                pagination?.length === 0 || pagination?.last || loading
              }
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "5px",
            }}
          >
            <Typography style={{ color: "#8E8E8E" }}>
              Showing {start} to {end} of {total}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
