import {
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Tooltip,
  useTheme,
} from "@mui/material";
import { FormBuilderColumnSchema } from "../../constants";
import EditIcon from "@mui/icons-material/Edit";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { CustomPagination } from "../custom-pagination/CustomPagination";
import moment from "moment";
import { theme } from "../../utilities";

const TableRowCellStyled = styled(TableCell)(({ theme }) => ({
  "&:first-child": {
    textTransform: "uppercase",
    padding: "0px 30px",
  },
  "&:nth-child(4)": {
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textAlign: "left",
  fontSize: "0.90rem",
  padding: "20px 20px",
  minWidth: "100px",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  overflow: "hidden",
  textOverflow: "ellipsis",
  borderBottom: "1px solid #e0e0e0",
  "&:last-child": {
    width: "150px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "8px 12px",
    fontSize: "0.80rem",
  },
}));

const TableColumnCellStyled = styled(TableCell)(() => ({
  "&:first-child": {
    padding: "0 30px",
  },
  textAlign: "left",
  fontSize: ".95rem",
  fontWeight: "bold",
  textTransform: "capitalize",
  padding: "20px 20px",
  width: "auto",
  borderBottom: "1px solid #e0e0e0",
  backgroundColor: "#f7f7f7",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "&:last-child": {
    width: "150px",
  },
}));

// const CustomIconButton = styled(IconButton)(({ disabled }) => ({
//   opacity: disabled ? 0.2 : 1,
//   pointerEvents: disabled ? "none" : "",
// }));

export const DashboardTable = ({
  data,
  handleDisplayData,
  pagination,
  handleChangePage,
  loading,
  formsPageNumber,
  handleSort,
  sorting,
}) => {
  const theme = useTheme();

  const statusColors = {
    1: "#FAAA4C", // Draft
    2: "#379f86", // Published
    3: "#D14B56", // Unpublished
  };

  const handleStatusColor = (formStatus) => {
    return statusColors[formStatus] || "#000";
  };

  const handleNextPage = () => {
    handleChangePage(formsPageNumber + 1);
  };

  const handlePreviousPage = () => {
    handleChangePage(formsPageNumber - 1);
  };

  const totalRange = () => {
    const start = data?.length > 0 ?  pagination?.pageable?.pageNumber * pagination?.size + 1 : 0;
    const end =data?.length > 0 ?  Math.min(
      pagination?.pageable?.pageNumber * pagination?.size +
        pagination?.numberOfElements,
      pagination?.totalElements
    ) : 0;
    const total = data?.length > 0 ?  pagination?.totalElements : 0;
    return { start, end, total };
  };

  const { start, end, total } = totalRange();

  return (
    <>
      <TableContainer
        sx={{
          height: "100%",
          margin: "0% 3%",
          marginTop: "2%",
        }}
        component={Paper}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {FormBuilderColumnSchema.map((column) => (
                <TableColumnCellStyled key={column.id}>
                  {column.label}
                  {column.hasSorting && (
                    <IconButton
                      onClick={() => {
                        handleSort(column.id);
                      }}
                      sx={{ width: 20, height: 20 }}
                    >
                      {sorting.column === column.id &&
                      sorting.order === "ASC" ? (
                        <ArrowUpwardIcon
                          sx={{ color: "#000", fontSize: "18px" }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{ color: "#000", fontSize: "18px" }}
                        />
                      )}
                    </IconButton>
                  )}
                </TableColumnCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <TableRow key={index}>
                <TableRowCellStyled>{row.id ?? ""}</TableRowCellStyled>
                <TableRowCellStyled>{row.formName ?? ""}</TableRowCellStyled>
                <TableRowCellStyled
                  sx={{ textTransform: "capitalize", justifyContent: "left" }}
                >
                  {row.version.split(" ")[1]}
                </TableRowCellStyled>
                <TableRowCellStyled>
                  {row.filerSections?.map((item) => (
                    <Chip
                      color="success"
                      sx={{
                        color: "#000",
                        margin: 0.2,
                        background: `${theme.palette.common.lightGrey}60`,
                      }}
                      label={item.name}
                    />
                  ))}
                </TableRowCellStyled>
                <TableRowCellStyled>
                  {row.sectionOwner?.name}
                </TableRowCellStyled>
                <TableRowCellStyled>
                  {row.tags?.map((item) => (
                    <Chip
                      color="success"
                      sx={{
                        color: "#000",
                        margin: 0.2,
                        background: `${theme.palette.common.lightGrey}60`,
                      }}
                      label={item}
                    />
                  ))}
                </TableRowCellStyled>
                <TableRowCellStyled>
                  {moment(row.dateModified).format("YYYY-MM-DD")}
                </TableRowCellStyled>
                <TableRowCellStyled>
                  {row.modifiedBy?.fullname}
                </TableRowCellStyled>
                <TableRowCellStyled>
                  <Chip
                    color="default"
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      backgroundColor: handleStatusColor(row.formStatus),
                    }}
                    label={
                      row.formStatus === 1
                        ? "DRAFT"
                        : row.formStatus === 2
                        ? "PUBLISHED"
                        : "UNPUBLISHED"
                    }
                  />
                </TableRowCellStyled>
                <TableRowCellStyled>
                  <Grid container gap={0.5} justifyContent="left">
                    <Tooltip title="Update Form" placement="top">
                      <IconButton onClick={() => handleDisplayData(row)}>
                        <EditIcon sx={{ color: "#444444", fontSize: "25px" }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </TableRowCellStyled>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {!loading && data.length === 0 && (
          <Box marginTop={2}>
            <Typography textAlign={"center"}>No Data Available</Typography>
          </Box>
        )}
      </TableContainer>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="0px 20px"
        backgroundColor="#ffffff"
        width="100%"
        borderRadius="2px"
        margin="0% 3%"
      >
        <Grid container alignItems="center">
          <Grid item xs={4} />
          <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
            <CustomPagination
              pageNumber={formsPageNumber ?? 1}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              disableFirst={pagination?.length === 0 || pagination?.first}
              disableNext={
                pagination?.length === 0 || pagination?.last || loading
              }
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "5px",
            }}
          >
            <Typography style={{ color: "#8E8E8E" }}>
              Showing {start} to {end} of {total}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
