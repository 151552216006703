import React from 'react';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ component: Component }) => {
  const userRoles = JSON.parse(localStorage.getItem("role"));
  
  if (userRoles.includes("SUPERADMIN")) {
    return <Component />;
  } else {
    return <Navigate to="/" replace />;
  }
};
