import React from "react";
import { NodeActionsWidget } from "@designable/react";

const LoadTemplate = (props) => {
  return (
    <NodeActionsWidget>
      {props.actions &&
        props.actions.map((action, key) => {
          return <NodeActionsWidget.Action {...action} key={key} />;
        })}
    </NodeActionsWidget>
  );
};

export default LoadTemplate;
