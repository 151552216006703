import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
  useReducer,
} from "react";
import { viewerActions, viewerReducer, viewwerInitialState } from "../reducer";
import { useViewersData } from "../utilities";
import { RESET_INITIAL_STATE } from "../constants";

const ViewerContext = createContext();

export const useViewerContext = () => {
  return useContext(ViewerContext);
};

export const ViewerContextProvider = ({ children }) => {
  const {
    viewers,
    viewersPageNumber,
    viewersPageSize,
    searchKeyword,
    optimizedSearchViewers,
    mutateViewers,
    sort,
    viewerStatus,
  } = useViewersData("form-viewer/search?");

  const [viewerState, dispatchViewer] = useReducer(viewerReducer, {
    ...viewwerInitialState,
    pageViewers: viewers,
  });

  useEffect(() => {
    mutateViewers();
    dispatchViewer({type: RESET_INITIAL_STATE})
  }, [
    viewers,
    viewersPageNumber,
    viewersPageSize,
    searchKeyword,
    sort,
    viewerStatus,
  ]);

  const [actions, setActions] = useState({});
  const stateRef = useRef();

  useEffect(() => {
    Object.keys(viewerActions).forEach((key) => {
      setActions((curr) => ({
        ...curr,
        [key]: (...args) => {
          viewerActions[key](...args)(dispatchViewer, stateRef.current);
        },
      }));
    });
  }, []);

  useEffect(() => {
    stateRef.current = viewerState;
  }, [viewerState]);

  const value = useMemo(
    () => ({
      viewerState,
      dispatchViewer,
      viewers,
      viewersPageNumber,
      viewersPageSize,
      searchKeyword,
      mutateViewers,
      optimizedSearchViewers,
      actions,
      sort,
      viewerStatus,
    }),
    [
      viewerState,
      dispatchViewer,
      viewers,
      viewersPageNumber,
      viewersPageSize,
      searchKeyword,
      mutateViewers,
      optimizedSearchViewers,
      actions,
      sort,
      viewerStatus,
    ]
  );

  return (
    <ViewerContext.Provider value={value}>{children}</ViewerContext.Provider>
  );
};
