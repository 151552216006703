import useAxios from "../utilities/useAxios";

const BACKEND_DEV_URL = process.env.REACT_APP_BACKEND_URL;

export const addForm = async (data) => {
  return useAxios
    .post(`${BACKEND_DEV_URL}/forms`, data)
    .catch((error) => console.error(error));
};

export const updateForm = async (data) => {
  return useAxios
    .put(`${BACKEND_DEV_URL}/forms/without-schema/${data.id}`, data)
    .catch((error) => console.error(error));
};

export const updateFormStatus = async (data) => {
  return useAxios
    .put(`${BACKEND_DEV_URL}/form-designs/${data.id}`, data)
    .catch((error) => console.error(error));
};

export const saveSchema = async (data) => {
  return useAxios
    .post(`${BACKEND_DEV_URL}/form-designs/update-form-design`, data)
    .catch((error) => console.error(error));
}

