export const handleFormBuilderStatus = (value) => {
  switch (value) {
    case 1:
      return "warning";
    case 2:
      return "success";
    case 3:
      return "error";
    default:
      return "primary";
  }
};

export const handleUserStatus = (value) => {
  switch (value) {
    case 1:
      return "success";
    case 0:
      return "warning";
    default:
      return "primary";
  }
};

export const handleViewerStatus = (value) => {
  switch (value) {
    case "APPROVED":
      return "success";
    case "DENIED":
      return "error";
    default:
      return "warning";
  }
};
