export const FormBuilderColumnSchema = [
  { id: "form. id", label: "Form Id", hasSorting: false },
  { id: "form.name", label: "Form Name", hasSorting: false },
  { id: "version", label: "Version", hasSorting: false },
  { id: "section", label: "Section", hasSorting: false },
  { id: "form.section.name", label: "Section Owner", hasSorting: false }, 
  { id: "tags", label: "Tags", hasSorting: false },
  { id: "dateModified", label: "Last Modified", hasSorting: false },
  { id: "modifiedBy", label: "Modified By", hasSorting: false },
  { id: "status", label: "Status", hasSorting: false },
  { id: "action", label: "Action", hasSorting: false },
];

export const FormBuilderRowData = [
  {
    id: 1,
    formName: "Meeting Room Reservation",
    tags: ["Testing", "New Testing"],
    description: "This is just a test",
    sectionOwner: ["Section 1"],
    filerSection: ["Section 4"],
    filer: ["TORNO, KENT MICHAEL CASTILLO"],
    viewerAccess: ["TORNO, KENT MICHAEL CASTILLO", "ANGCO, ALLYSA"],
    remarks: "This is just sample remarks",
    version: "2",
    section: "HR",
    lastModified: "11-07-2023",
    modifiedBy: "John Doe",
    status: "Published",
  },
  {
    id: 2,
    formName: "Gate Pass",
    tags: ["Testing", "New Testing"],
    description: "This is just a test",
    sectionOwner: ["Section 2"],
    filerSection: ["Section 5"],
    filer: ["TORNO, KENT MICHAEL CASTILLO", "SANTOS, JAP"],
    viewerAccess: ["SANTOS, JAP", "ANGCO, ALLYSA"],
    remarks: "This is just sample remarks",
    version: "2",
    section: "HR",
    lastModified: "11-07-2023",
    modifiedBy: "John Doe",
    status: "Published",
  },
  {
    id: 3,
    formName: "Incident Report",
    tags: ["Testing", "New Testing"],
    description: "This is just a test",
    sectionOwner: ["Section 3"],
    filerSection: ["Section 1"],
    filer: ["TORNO, KENT MICHAEL CASTILLO"],
    viewerAccess: ["TORNO, KENT MICHAEL CASTILLO", "ANGCO, ALLYSA"],
    remarks: "This is just sample remarks",
    version: "2",
    section: "HR",
    lastModified: "11-07-2023",
    modifiedBy: "John Doe",
    status: "Unpublished",
  },
  {
    id: 4,
    formName: "Travel Expense Reimbursement",
    tags: ["Testing", "New Testing"],
    description: "This is just a test",
    sectionOwner: ["Section 1"],
    filerSection: ["Section 2"],
    filer: ["TORNO, KENT MICHAEL CASTILLO"],
    viewerAccess: ["TORNO, KENT MICHAEL CASTILLO", "ANGCO, ALLYSA"],
    remarks: "This is just sample remarks",
    version: "2",
    section: "HR",
    lastModified: "11-07-2023",
    modifiedBy: "John Doe",
    status: "Draft",
  },
  {
    id: 5,
    formName: "Leave of Absence",
    tags: ["Testing", "New Testing"],
    description: "This is just a test",
    sectionOwner: ["Section 2"],
    filerSection: ["Section 1"],
    filer: ["TORNO, KENT MICHAEL CASTILLO", "SANTOS, JAP", "ANGCO, ALLYSA"],
    viewerAccess: ["SANTOS, JAP", "ANGCO, ALLYSA"],
    remarks: "This is just sample remarks",
    version: "2",
    section: "HR",
    lastModified: "11-07-2023",
    modifiedBy: "John Doe",
    status: "Published",
  },
  {
    id: 6,
    formName: "Employee Evaluation",
    tags: ["Testing", "New Testing"],
    description: "This is just a test",
    sectionOwner: ["Section 3"],
    filerSection: ["Section 3"],
    filer: ["SANTOS, JAP", "ANGCO, ALLYSA"],
    viewerAccess: ["TORNO, KENT MICHAEL CASTILLO", "SANTOS, JAP"],
    remarks: "This is just sample remarks",
    version: "2",
    section: "HR",
    lastModified: "11-07-2023",
    modifiedBy: "John Doe",
    status: "Draft",
  },
];

export const UserMaintenanceColumnSchema = [
  { id: "employee_id", label: "Employee Number", hasSorting: false },
  { id: "fullname", label: "Employee Name", hasSorting: false },
  { id: "section", label: "Section", hasSorting: false },
  { id: "date_created", label: "Date Created", hasSorting: false },
  { id: "status", label: "Status", hasSorting: false },
  { id: "action", label: "Action", hasSorting: false },
];

export const ViewerMaintenanceColumnSchema = [
  { id: "formName", label: "Form Name", hasSorting: false },
  { id: "requestorName", label: "Requestor Name", hasSorting: false },
  { id: "userSection", label: "Section Owner", hasSorting: false },
  { id: "status", label: "Status", hasSorting: false },
  { id: "action", label: "Action", hasSorting: false },
];

export const UploadMaintenanceColumnSchema = [
  { id: "type", label: "File Type", hasSorting: false },
  { id: "name", label: "File Name", hasSorting: false },
  { id: "url", label: "URL link", hasSorting: false },
  { id: "section", label: "Section", hasSorting: false},
  { id: "dateCreated", label: "Date Created", hasSorting: true },
  { id: "action", label: "Action", hasSorting: false }
];

export const UserMaintenanceRowData = [
  {
    id: 1,
    userNumber: "12701",
    employeeName: "TORNO, KENT MICHAEL CASTILLO",
    section: "IT",
    dateCreated: "January 25, 2023",
    status: "Active",
  },
  {
    id: 2,
    userNumber: "10030",
    employeeName: "EBREO, FELECITAS CANTINA",
    section: "HR",
    dateCreated: "February 25, 2023",
    status: "Active",
  },
  {
    id: 3,
    userNumber: "15367  ",
    employeeName: "ANGELES, ERIKA GAPAC",
    section: "Painting",
    dateCreated: "March 25, 2023",
    status: "Inactive",
  },
];
