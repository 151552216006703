import { Formik } from "formik";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ButtonText, MainDialog, StandardButton } from "../../../../components";
import { FormikTouched } from "../../../../utilities";
import { UserValidationSchema } from "../../../../constants";
import { useGlobalContext } from "../../../../context";

export const UserForm = ({
  innerRef,
  values,
  handleSubmit,
  handleAlertMessage,
  open,
  onClose,
  validateRef,
  setTouchedRef,
  userData,
  handleFetchEmployees,
  employeeNames,
  loading,
}) => {
  const { sectionsData } = useGlobalContext();
  const employeeNameRef = useRef(null);
  const sectionRef = useRef(null);
  const statusRef = useRef(null);

  useEffect(() => {
    if (values?.fullname) {
      handleFetchEmployees(values.fullname);
    }
  }, [values?.fullname]);
  return (
    <Formik
      innerRef={innerRef}
      initialValues={{ sections: [], ...values, fullname: userData ?? {} }}
      onSubmit={handleSubmit}
      validationSchema={UserValidationSchema}
    >
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        isValid,
        touched,
        setTouched,
        handleBlur,
        validateForm,
      }) => {
        if (validateRef) validateRef.current = validateForm;
        if (setTouchedRef) setTouchedRef.current = setTouched;

        return (
          <MainDialog
            title={values?.id ? "Update User " : "Create User"}
            showDialog={open}
            handleClose={onClose}
            widthType={"sm"}
          >
            <FormikTouched />
            <Grid container rowSpacing={1.8} paddingX={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  type="text"
                  variant="outlined"
                  id="employeeId"
                  name="employeeId"
                  value={values.employeeId}
                  label={!values?.employeeId ? "Employee Id" : null}
                  size="small"
                  fullWidth
                  disabled
                  onChange={handleChange}
                />
              </Grid>
              {/* <Grid item xs={12} md={12}>
                <Autocomplete
                  id="fullname"
                  name="fullname"
                  value={values?.fullname ?? ""}
                  options={sortedEmployeeNames}
                  onChange={(e, newValue) => {
                    setFieldValue("fullname", newValue);
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") {
                      return false;
                    }
                    return option === value;
                  }}
                  disabled={values?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      shrink
                      size="small"
                      ref={employeeNameRef}
                      required
                      helperText={touched.fullname && errors.fullname}
                      error={Boolean(errors.fullname && touched.fullname)}
                      onBlur={handleBlur}
                      label="Employee Name"
                    />
                  )}
                />
              </Grid> */}
              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="fullname"
                  name="fullname"
                  value={values?.fullname ?? ""}
                  options={employeeNames.sort((a, b) =>
                    a.fullname.localeCompare(b.fullname)
                  )}
                  onChange={(e, newValue) => {
                    if (newValue === null) {
                      // Clear the values when clear icon is clicked
                      setFieldValue("fullname", {});
                      setFieldValue("employeeId", "");
                      setFieldValue("sections", []);
                    } else {
                      setFieldValue("fullname", newValue);
                      setFieldValue("employeeId", newValue?.employeeId);
                      setFieldValue("sections", newValue?.sections);
                      setFieldValue(
                        "status",
                        newValue?.status === 1 ? "ACTIVE" : "INACTIVE"
                      );
                    }
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return (
                      option?.employeeId === value?.employeeId &&
                      option?.id === value?.id
                    );
                  }}
                  disabled={values?.id}
                  getOptionLabel={(option) => option?.fullname || ""}
                  loading={loading && Object.keys(userData).length === 0}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      ref={employeeNameRef}
                      required
                      helperText={touched.fullname && errors.fullname}
                      error={Boolean(errors.fullname && touched.fullname)}
                      onBlur={handleBlur}
                      label="Employee Name"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading && Object.keys(userData).length === 0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Autocomplete
                  multiple
                  id="sections"
                  name="sections"
                  value={values?.sections}
                  options={sectionsData}
                  getOptionLabel={(option) => option?.name}
                  onChange={(e, newValue) => {
                    setFieldValue("sections", newValue);
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return option?.id === value?.id;
                  }}
                  disabled={loading && Object.keys(userData).length === 0}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      ref={sectionRef}
                      required
                      helperText={touched.sections && errors.sections}
                      error={Boolean(errors.sections && touched.sections)}
                      onBlur={handleBlur}
                      label="Section"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="status"
                  name="status"
                  disableClearable
                  value={
                    values.id
                      ? values.status === 1
                        ? "ACTIVE"
                        : "INACTIVE"
                      : values.status ?? ""
                  }
                  onChange={(e, newValue) => {
                    setFieldValue("status", newValue);
                  }}
                  options={["ACTIVE", "INACTIVE"]}
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") {
                      return false;
                    }
                    return option === value;
                  }}
                  disabled={loading && Object.keys(userData).length === 0}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      ref={statusRef}
                      required
                      helperText={touched.status && errors.status}
                      error={Boolean(errors.status && touched.status)}
                      onBlur={handleBlur}
                      label="Status"
                    />
                  )}
                />
              </Grid>

              <Grid container marginTop={"3%"} justifyContent="flex-end">
                <StandardButton
                  type="submit"
                  size="small"
                  onClick={(e) => {
                    isValid
                      ? handleSubmit(values)
                      : handleAlertMessage(
                          "Please resolve all fields",
                          "error"
                        );
                  }}
                >
                  <ButtonText>{values?.id ? "Update" : "Submit"}</ButtonText>
                </StandardButton>
              </Grid>
            </Grid>
          </MainDialog>
        );
      }}
    </Formik>
  );
};
