import { Alert, Snackbar, styled } from "@mui/material";

const AlertStyled = styled(Alert)((alertSeverity) => ({
  color: "#000000",
}));

export const SystemMessage = ({
  showAlert,
  handleCloseAlert,
  alertSeverity,
  alertMessage,
}) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={showAlert}
      onClose={handleCloseAlert}
      autoHideDuration={3000}
    >
      <AlertStyled
        onClose={handleCloseAlert}
        elevation={6}
        variant="filled"
        severity={alertSeverity}
        sx={{
          background: alertSeverity === "success" ? "#27ab0e" : "",
          color: "#000000",
        }}
      >
        {alertMessage}
      </AlertStyled>
    </Snackbar>
  );
};
