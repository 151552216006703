import { useState } from "react";

export const useModal = (isOpen = false) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return {
    isModalOpen,
    showModal,
    closeModal,
  };
};
